import React, { useRef } from "react";
import "./PopoverMenu.css";
import PropTypes from "prop-types";
const Option = ({ children, onClick }) => {
  return (
    <button className="popover-menu__option" role="menuitem" onClick={onClick}>
      {children}
    </button>
  );
};

Option.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};
const PopoverMenu = ({ isOpen, children, style }) => {
  const popoverRef = useRef(null);

  if (!isOpen) return null;

  return (
    <div
      className="popover-menu__popover nodrag"
      ref={popoverRef}
      style={{
        position: "absolute",
        top: `-225px`,
        ...style,
      }}
    >
      <div className="popover-menu__list" role="menu">
        {children}
      </div>
    </div>
  );
};

PopoverMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  style: PropTypes.object,
};

PopoverMenu.Option = Option;
export default PopoverMenu;
