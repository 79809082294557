import React, { useEffect, useState } from "react";
import "./Input.css";
import Icon from "@mui/material/Icon";
import { Tooltip } from "@mui/material";
import { useDateValidator } from "src/modules/hooks/useDateValidator";
// type: text, search, password, email, tel, checkbox
// autocomplete : on,off (agregar new- para evitar que autcomplete new-password)
const Input = ({
  type = "text",
  placeholder = "text",
  onChange,
  value,
  disabled,
  label,
  styleInputContainer,
  unit,
  onBlur,
  defaultValue,
  regex,
  tooltip,
  autocomplete,
  ...props
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [onFocusValue, setOnFocusValue] = useState("");
  const { validateDate } = useDateValidator()
  const handleToggle = () => {
    if (passwordShown) {
      setPasswordShown(false);
    } else {
      setPasswordShown(true);
    }
  };

  const validNumber = (e) => {
    if ((e.target.type === 'number' && e.target.validity.badInput) || isNaN(e.target.value)) {
      e.target.style.backgroundColor = "var(--error-color)";
      e.target.style.borderColor = "var(--error-color)";
      e.target.focus();
      return false;
    }
    e.target.style.backgroundColor = null;
    e.target.style.borderColor = null;
    return true;
  };

  const validDate = (e) => {
    const date = validateDate(e.target.value)
    if(!date && e.target.validity.badInput) {
      e.target.style.backgroundColor = "var(--error-color)";
      e.target.style.borderColor = "var(--error-color)";
      e.target.focus();
      return false;
    }
    e.target.style.backgroundColor = null;
    e.target.style.borderColor = null;
    return true;
  }

  const allOk = (e) => {
    if(type === 'number') return validNumber(e)
    else if(type.includes('date')) return validDate(e)
    return true;
  };

  const onFocus = (e) => {
    if (!onBlur) return;
    setOnFocusValue(e.target.value);
  };

  const handleOnChange = (e) => {
    if (!onChange || !allOk(e)) return;
    onChange(e);
  };

  const handleOnBlur = (e) => {
    if (!allOk(e) || !onBlur || onFocusValue === e.target.value) return;
    onBlur(e);
  };

  const openUrl = () => {
    window.open(value ?? defaultValue, "_blank");
  };

  // Se define qué caracteres se pueden escribir en un input según su tipo
  let checked = props.checked;
  if (checked === "true") checked = true;
  else if (checked === "false") checked = false;

  if (type === "number") {
    const workingValue = value !== undefined ? value : defaultValue;
    let myType = type;
    if (isNaN(workingValue)) {
      myType = "text"; // Si no es un número válido, muestra como texto
    }
    return (
      <div className="input-container" style={styleInputContainer}>
        {label && (
          <label className="input-label">
            {label}
            {tooltip && (
              <Tooltip title={tooltip}>
                <Icon className="info-icon" size={20}>
                  info
                </Icon>
              </Tooltip>
            )}
          </label>
        )}
        <input
          name={props.name}
          type={myType}
          autoComplete={autocomplete ?? ""}
          placeholder={placeholder}
          onChange={handleOnChange}
          className={type ? `input-${type}` : "input-text"}
          value={value ? workingValue : undefined}
          defaultValue={defaultValue ? workingValue : undefined}
          style={{
            ...props.style,
            background: myType !== "number" ? "var(--warning-color)" : null,
          }}
          onFocus={onFocus}
          checked={checked}
          disabled={disabled ? "disabled" : ""}
          onBlur={handleOnBlur}
        />
      </div>
    );
  }

  if(type.includes('date')) {
    let workingValue = value !== undefined ? value : defaultValue;
    let myType = type;
    const validatedDate = validateDate(workingValue)
    if (!validatedDate && workingValue.trim().length > 0) {
      myType = "text"; // Si no es un número válido, muestra como texto
    } else {
      workingValue = validatedDate ? validatedDate.formatted[type] : workingValue
    }
    return (
      <div className="input-container" style={styleInputContainer}>
        {label && (
          <label className="input-label">
            {label}
            {tooltip && (
              <Tooltip title={tooltip}>
                <Icon className="info-icon" size={20}>
                  info
                </Icon>
              </Tooltip>
            )}
          </label>
        )}
        <input
          name={props.name}
          type={myType}
          autoComplete={autocomplete ?? ""}
          placeholder={placeholder}
          onChange={handleOnChange}
          className={type ? `input-${type}` : "input-text"}
          value={value ? workingValue : undefined}
          defaultValue={defaultValue ? workingValue : undefined}
          style={{
            ...props.style,
            background: !myType.includes("date") ? "var(--warning-color)" : null,
          }}
          onFocus={onFocus}
          checked={checked}
          disabled={disabled ? "disabled" : ""}
          onBlur={handleOnBlur}
        />
      </div>
    );
  }

  try {
    return (
      <>
        <div className="input-container" style={styleInputContainer}>
          {label && (
            <label className="input-label">
              {label}
              {tooltip && (
                <Tooltip title={tooltip}>
                  <Icon className="info-icon" size={20}>
                    info
                  </Icon>
                </Tooltip>
              )}
            </label>
          )}
          {type === "checkbox" ? (
            <label className="custom-checkbox">
              <input
                name={props.name}
                type="checkbox"
                checked={checked || value}
                defaultChecked={defaultValue}
                onChange={handleOnChange}
                disabled={disabled ? "disabled" : ""}
                onBlur={handleOnBlur}
                onFocus={onFocus}
              />
            </label>
          ) : (
            <input
              name={props.name}
              type={
                type === "password"
                  ? passwordShown
                    ? "text"
                    : "password"
                  : type || "text"
              }
              autoComplete={autocomplete ? autocomplete : ""}
              placeholder={placeholder}
              onChange={handleOnChange}
              className={type ? `input-${type}` : "input-text"}
              value={
                type !== "datetime-local" && type !== "date"
                  ? value
                  : value
                  ? new Date(value).toISOString("es-ES").replace(/Z/gi, "")
                  : ""
              }
              defaultValue={
                type !== "datetime-local" && type !== "date"
                  ? defaultValue
                  : defaultValue
                  ? type === "datetime-local"
                    ? new Date(defaultValue)
                        .toISOString("es-ES")
                        .replace(/Z/gi, "")
                    : new Date(defaultValue).toISOString("es-ES").split("T")[0]
                  : ""
              }
              style={props.style}
              checked={checked}
              disabled={disabled ? "disabled" : ""}
              onBlur={handleOnBlur}
              onFocus={onFocus}
            />
          )}
          {type === "password" && (
            <>
              {passwordShown ? (
                <Icon onClick={handleToggle} className="eye" size={25}>
                  visibility
                </Icon>
              ) : (
                <Icon onClick={handleToggle} className="eye" size={25}>
                  visibility_off
                </Icon>
              )}
            </>
          )}
          {type === "search" && (
            <Icon className="search" size={25}>
              search
            </Icon>
          )}
          {type === "url" && (
            <Icon onClick={openUrl} className="eye" fontSize="small" size={12}>
              open_in_new
            </Icon>
          )}
          {unit && <span className="unit">{unit}</span>}
        </div>
      </>
    );
  } catch (error) {
    console.log(props.name, defaultValue, error);
  }
};

export default Input;
