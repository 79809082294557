// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popover-menu__popover {
  z-index: 1000;
  background-color: #1e1e1e;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 150px;
}

.popover-menu__list {
  padding: 4px 0;
  margin: 0;
  list-style: none
}

.popover-menu__option {
  display: block;
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;
  text-align: left;
  font-size: 14px;
  color: white;
  cursor: pointer;
  font-family: 'Ping LCG';
}

.popover-menu__option:hover {
  background-color: rgb(82, 82, 82);
}`, "",{"version":3,"sources":["webpack://./src/modules/components/common/Planner/Nodes/NotesNode/NoteEditor/PopoverMenu/PopoverMenu.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,yCAAyC;EACzC,oCAAoC;EACpC,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,SAAS;EACT;AACF;;AAEA;EACE,cAAc;EACd,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":[".popover-menu__popover {\r\n  z-index: 1000;\r\n  background-color: #1e1e1e;\r\n  border-radius: 8px;\r\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\r\n  border: 1px solid rgba(0, 0, 0, 0.1);\r\n  min-width: 150px;\r\n}\r\n\r\n.popover-menu__list {\r\n  padding: 4px 0;\r\n  margin: 0;\r\n  list-style: none\r\n}\r\n\r\n.popover-menu__option {\r\n  display: block;\r\n  width: 100%;\r\n  padding: 8px 16px;\r\n  border: none;\r\n  background: none;\r\n  text-align: left;\r\n  font-size: 14px;\r\n  color: white;\r\n  cursor: pointer;\r\n  font-family: 'Ping LCG';\r\n}\r\n\r\n.popover-menu__option:hover {\r\n  background-color: rgb(82, 82, 82);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
