import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Cartridge, Filetype, Global } from "src/lib/api";
import Input from "src/modules/components/common/Input/Input";
import InputWithImport from "src/modules/components/common/InputWithImport/InputWithImport";
import Select from "src/modules/components/common/Select/Select";
import TextArea from "src/modules/components/common/Text Area/TextArea";

const CartridgeTab = ({ cartridge, setCartridge }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const parametroCartridgeCode = queryParams.get("cartridgecode");

  const [filetypeOptions, setFileTypeOptions] = useState([]);

  useEffect(() => {
    if (parametroCartridgeCode) {
      getCartridge(parametroCartridgeCode);
    }
    getFiletypes();
  }, []);

  const getFiletypes = async () => {
    const filetypes = await Filetype.list();
    const options = filetypes.map((filetype) => ({
      label: filetype.name,
      value: filetype.name,
    }));
    setFileTypeOptions(options);
  };

  const getCartridge = async (input) => {
    const cartridge = await Cartridge.read(input);
    if (!cartridge) return;
    setCartridge({
      cartridgecode: cartridge.cartridgecode,
      name: cartridge.name,
      description: cartridge.description,
      group: cartridge.group,
      imgurl: cartridge.imgurl,
      filetype: cartridge.filetype,
      logic: cartridge.logic,
      videourl: cartridge.videourl,
      public: cartridge.public,
      active: cartridge.active,
      planner: cartridge.planner,
    });
  };

  const handleSelectChange = (name, event) => {
    const newCartridge = { ...cartridge, [name]: event ? event.value : "" };
    setCartridge(newCartridge);
  };

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    if ((name === "imgurl" || name === "videourl") && event.target.file) {
      const uploadFile = event.target.files
        ? event.target.files[0]
        : event.dataTransfer.files[0];
      const formData = {
        file: uploadFile,
        target: "public",
        path: "cartridge",
      };
      Global.upload(formData)
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    setCartridge({ ...cartridge, [name]: value });
  };

  return (
    <div className="css-175oi2r" style={{ marginTop: "10px" }}>
      <Input
        name="cartridgecode"
        type="text"
        placeholder="CDC0001"
        value={cartridge?.cartridgecode}
        onChange={handleInputChange}
        label="Código:"
      />
      <Input
        name="name"
        type="text"
        placeholder="Nacionalidad española por residencias"
        value={cartridge?.name}
        onChange={handleInputChange}
        label="Nombre:"
      />
      <TextArea
        name="description"
        type="text"
        placeholder="Description"
        value={cartridge?.description}
        onChange={handleInputChange}
        label="Descripción:"
      />
      <Select
        onChange={(val) => handleSelectChange("filetype", val)}
        name="type"
        value={
          filetypeOptions.find(
            (filetype) => filetype.value === cartridge?.filetype
          ) || null
        }
        placeholder="exp"
        options={filetypeOptions}
        label="Filetype"
      />
      <Input
        name="group"
        type="text"
        placeholder="Extranjeria"
        value={cartridge?.group}
        onChange={handleInputChange}
        label="Grupo:"
      />
      <InputWithImport
        name="imgurl"
        label="Imagen"
        onChange={handleInputChange}
        url={cartridge?.imgurl}
      />
      <InputWithImport
        name="videourl"
        label="Video"
        onChange={handleInputChange}
        url={cartridge?.videourl}
      />
      <TextArea
        name="logic"
        type="text"
        placeholder="Lógica"
        value={cartridge?.logic}
        onChange={handleInputChange}
        label="Logic:"
      />
      <Input
        name="public"
        type="checkbox"
        placeholder="public"
        value={cartridge?.public}
        onChange={handleInputChange}
        label="Público:"
      />
      <Input
        name="active"
        type="checkbox"
        placeholder="active"
        value={cartridge?.active}
        onChange={handleInputChange}
        label="Activo:"
      />
    </div>
  );
};

export default CartridgeTab;
