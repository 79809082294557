export const useDateValidator = () => {
  const parseCustomDate = (dateString) => {
    const formats = [/^(\d{1,2})[-/](\d{1,2})[-/](\d{4})$/];

    for (let format of formats) {
      if(typeof dateString !== 'string') return null
      const match = dateString.match(format);
      if (match) {
        const [, day, month, year] = match;
        
        const paddedDay = day.padStart(2, '0');
        const paddedMonth = month.padStart(2, '0');
        
        const parsedDate = new Date(`${year}-${paddedMonth}-${paddedDay}`);
        
        if (
          parsedDate.getFullYear() === Number(year) &&
          parsedDate.getMonth() === Number(month) - 1 &&
          parsedDate.getDate() === Number(day)
        ) {
          return parsedDate;
        }
      }
    }
    return null;
  };

  const validateDate = (dateString) => {
    const parsedDate = parseCustomDate(dateString) || new Date(dateString);

    if (!parsedDate || isNaN(parsedDate.getTime())) return null;

    const pad = (num) => num.toString().padStart(2, '0');

    return {
      original: dateString,
      formatted: {
        'date': `${parsedDate.getFullYear()}-${pad(parsedDate.getMonth() + 1)}-${pad(parsedDate.getDate())}`,
        'datetime-local': `${parsedDate.getFullYear()}-${pad(parsedDate.getMonth() + 1)}-${pad(parsedDate.getDate())}T${pad(parsedDate.getHours())}:${pad(parsedDate.getMinutes())}`,
        iso: parsedDate.toISOString().split('T')[0],
        localeDate: parsedDate.toLocaleDateString(),
      }
    };
  };

  return { validateDate };
};