import React, { useEffect, useState } from "react";
import { getIncomers } from "@xyflow/react";
import Planner from '../../../modules/components/common/Planner/Planner'
import constants from "src/definitions/constants";

const PlannerTab = ({cartridge, setBuildPlanner}) => {
  const [nodes, setNodes] = useState(null);
  const [edges, setEdges] = useState(null);
  const onChangeNodes = (nodesPlanner, edgesPlanner) => {
    setNodes(nodesPlanner);
    setEdges(edgesPlanner);
  };

  const mapNodeDataByType = (node) => {
    const { data, measured } = node;

    const dataMappers = {
      createdoc: () => ({
        filename: data.filename ?? "",
        filetype: data.filetype ?? "",
        format: data.format ?? "raw",
        template: data.template ?? "",
      }),
      document: () => ({
        filename: data.filename ?? "",
        filetype: data.filetype ?? "",
        requestlimit: data.requestlimit ?? "",
        documentlimit: data.documentlimit ?? "",
        must: data.must ?? false,
        who: data.who ?? { label: "", value: "" },
      }),
      action: () => ({
        conductorname: data.conductorname,
      }),
      event: () => ({
        fixdays: data.fixdays,
        eventtype: data.eventtype,
        alerttext: data.alerttext,
        alertdays: data.alertdays,
      }),
      variable: () => [...data.variables],
      ai: () => ({
        agent: data.agent,
        prompt: data.prompt,
        variable: data.variable,
      }),
      note: () => {
        const theme = constants.stickyNoteThemes
          .map((theme) => theme.id)
          .includes(data.theme)
          ? data.theme
          : "default";
        return {
          content: data.content,
          width: measured.width,
          height: measured.height,
          theme,
        };
      },
      default: () => data,
    };

    return (dataMappers[data.type] || dataMappers.default)();
  };


  const createPlannerNode = (node, databytype) => ({
    id: node.id,
    name: node.data.name ?? "",
    type: node.data.type ?? "",
    logic: node.data.logic ?? "",
    pos: { x: node.position.x, y: node.position.y },
    depends: getIncomers(node, nodes, edges).map((inc) => inc.id),
    [node.data.type !== "variable" ? node.data.type : "info"]: databytype,
  });

  const createStickyNote = (node, databytype) => ({
    id: node.id,
    type: node.data.type ?? "",
    pos: { x: node.position.x, y: node.position.y },
    note: databytype,
  });

  const buildPlanner = () => {
    if (!nodes || !edges) return cartridge.planner;
  
    const nuevosNodos = nodes.map((node) => {
      const databytype = mapNodeDataByType(node);
      return node.type === constants.nodeByType.plannerNode
        ? createPlannerNode(node, databytype)
        : createStickyNote(node, databytype);
    });
  
    return nuevosNodos;
  };

  useEffect(() => {
    setBuildPlanner(buildPlanner)
  }, [setBuildPlanner, nodes, edges]);

  return (
    <div style={{ marginTop: "10px" }}>
      <Planner
        getInfo={onChangeNodes}
        initialNodes={cartridge?.planner}
        cartridge={cartridge ?? {}}
      />
    </div>
  );
};

export default PlannerTab;
