import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Event {
  static async list(period, first, pagination) {
    if (!first) first = 0;
    if (!pagination) pagination = 100;

    let response = await HttpMethods.request_get(
      `/v1/event/list/${period}/general?format=flat`
    );
    if (!response) {
      response = [];
      addErrorMessageGlobal("Error al obtener los eventos");
    }
    return response;
  }
}
