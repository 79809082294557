import React from "react";
import ColorPicker from "src/modules/components/common/ColorPicker/ColorPicker";
import Input from "src/modules/components/common/Input/Input";
import "./Aspecto.css";
import { useAspectoContext } from "./contexts/AspectoContextProvider";
import Demo from "./Demo/Demo";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const Aspecto = () => {
  //El contexto se encarga de crear las variables demo
  const { aspectoDemoVariables, setAspectoDemoVariables } = useAspectoContext();
  const { setupData, setSetupData } = useAppContext();
  const handleColorChangeContext = (color, name) => {
    setAspectoDemoVariables((prevValues) => ({
      ...prevValues,
      [name]: color,
    }));
  };

  const handleInputChangeAtApp = (event) => {
    const { name, value } = event.target;
    setSetupData((prevValues) => ({
      ...prevValues,
      app: {
        ...prevValues.app,
        [name]: value,
      },
    }));
  };

  return (
    <div className="aspecto-container">
      <div className="tab-container  tab-container-aspect">
        <ColorPicker
          name="--demo-primary-color"
          type="color"
          value={aspectoDemoVariables["--demo-primary-color"]}
          onChange={(value) =>
            handleColorChangeContext(value, "--demo-primary-color")
          }
          label="Color primario:"
        />
        <ColorPicker
          name="backcolor"
          type="color"
          value={aspectoDemoVariables["--demo-bg-color"]}
          onChange={(value) =>
            handleColorChangeContext(value, "--demo-bg-color")
          }
          label="Color de fondo:"
        />
        <ColorPicker
          name="detailcolor"
          type="color"
          value={aspectoDemoVariables["--demo-detail-color"]}
          onChange={(value) =>
            handleColorChangeContext(value, "--demo-detail-color")
          }
          label="Color de detalles:"
        />
        <ColorPicker
          name="fontcolor"
          type="color"
          value={aspectoDemoVariables["--demo-font-color"]}
          onChange={(value) =>
            handleColorChangeContext(value, "--demo-font-color")
          }
          label="Color de la letra:"
        />
        <ColorPicker
          name="errorcolor"
          type="color"
          value={aspectoDemoVariables["--demo-error-color"]}
          onChange={(value) =>
            handleColorChangeContext(value, "--demo-error-color")
          }
          label="Color de los errores:"
        />
        <ColorPicker
          name="warningcolor"
          type="color"
          value={aspectoDemoVariables["--demo-warning-color"]}
          onChange={(value) =>
            handleColorChangeContext(value, "--demo-warning-color")
          }
          label="Color de las alertas:"
        />
        <Input
          name="fonttype"
          type="text"
          placeholder="Seleccionable con distintos tipos"
          value={aspectoDemoVariables["--demo-font-family"]}
          onChange={(e) =>
            handleColorChangeContext(e.target.value, "--demo-font-family")
          }
          label="Fuente de letra:"
        />
        <Input
          name="logo"
          type="text"
          placeholder="/logointro.png"
          defaultValue={setupData.app?.logo}
          onChange={handleInputChangeAtApp}
          label="Logo de la empresa:"
        />
      </div>
      <div className="aspecto-container-demo">
        <Demo />
      </div>
    </div>
  );
};

export default Aspecto;
