import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Input from "../Input/Input";
import { Auth as AuthAPI } from "src/lib/api";
import "./Policies.css";
import Collapsible from "../Collapsible/Collapsible";
const Policies = ({ policies, setPolicies, opened }) => {
  const [policiesOptions, setPoliciesOptions] = useState([]);
  const fetchPolicies = useCallback(async () => {
    let newPolicies = await AuthAPI.getPolicies();
    newPolicies = newPolicies.map((el) => {
      return {
        value: el.policy,
        label: el.policy,
        description: el.description,
      };
    });
    setPoliciesOptions(newPolicies);
  }, [setPoliciesOptions]);

  useEffect(() => {
    fetchPolicies();
  }, [fetchPolicies]);

  const handleOnChange = (event, value) => {
    const { checked } = event.target;
    if (checked) {
      setPolicies([...policies, value]);
    } else {
      setPolicies(policies.filter((policy) => policy !== value));
    }
  };

  return (
    <div className="policies-container">
      <Collapsible
        header="Políticas"
        textStyle={{ display: "flex", justifyContent: "center" }}
        open={opened ? opened : false}
      >
        <div className="policies-container-checkboxed">
          {policiesOptions.map((policy) => {
            return (
              <div className="policies-checkbox" key={policy.value}>
                <Input
                  name="policy"
                  type="checkbox"
                  value={policies.includes(policy.value) ?? false}
                  onChange={(e) => handleOnChange(e, policy.value)}
                  label={policy.label}
                />
                <div className="policies-checkbox-description">
                  {policy.description}
                </div>
              </div>
            );
          })}
        </div>
      </Collapsible>
    </div>
  );
};

export default Policies;

Policies.propTypes = {
  policies: PropTypes.array.isRequired,
  setPolicies: PropTypes.func.isRequired,
  opened: PropTypes.bool,
};
