import React, { useEffect, useRef, useState } from "react";
import { SvgIcon } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import "./Notifications.css";
import PopoverMenu from "src/modules/components/common/Planner/Nodes/NotesNode/NoteEditor/PopoverMenu/PopoverMenu";
import NotificationItem from "./NotificationsItem/NotificationItem";
import useMessages from "src/modules/contexts/MessageContextProvider";

const Notifications = () => {
  const [popOverMenuIsOpen, setPopOverMenuIsOpen] = useState(false);
  const { persistentQueue, setPersistentQueue } = useMessages();
  const notificationsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target)
      ) {
        setPopOverMenuIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const clearNotifications = () => {
    sessionStorage.removeItem("notifications");
    setPersistentQueue([]);
  };

  const deleteOneNotification = (id) => {
    setPersistentQueue((prev) => {
      return prev.filter((el) => el.id !== id);
    });
  };

  const renderNoNotifications = () => {
    return (
      <div className="no-notifications">
        <div className="no-notifications-icon">
          <SvgIcon component={NotificationsIcon} />
        </div>
        <div className="no-notifications-text">
          Todavía no tiene notificaciones
        </div>
      </div>
    );
  };
  return (
    <div className="header__notifications" ref={notificationsRef}>
      <div
        className="header_notification_icon"
        onClick={() => setPopOverMenuIsOpen((prev) => !prev)}
      >
        <SvgIcon component={NotificationsIcon} />
        {persistentQueue.length > 0 && (
          <div className="header_notification_icon-count">
            {persistentQueue.length}
          </div>
        )}
      </div>

      <PopoverMenu
        isOpen={popOverMenuIsOpen}
        style={{
          top: "auto",
          right: "15px",
          minWidth: "500px",
          maxWidth: "500px",
        }}
      >
        <div className="notifications__header">
          <div className="notifications__header__title">Notificaciones</div>
          <div
            className="notifications__header__clearbutton"
            onClick={clearNotifications}
          >
            Borrar todo
          </div>
        </div>

        <div className="notifications__items">
          {persistentQueue.length === 0
            ? renderNoNotifications()
            : persistentQueue
                .slice() // Copia el array para no mutar el estado original
                .reverse()
                .map((item) => (
                  <NotificationItem
                    message={item.text}
                    status={item.type}
                    key={item.id}
                    id={item.id}
                    timeGeneratedAt={item.timeGeneratedAt}
                    onDelete={deleteOneNotification}
                  />
                ))}
        </div>
      </PopoverMenu>
    </div>
  );
};

export default Notifications;
