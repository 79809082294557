import React from 'react'
import Markdown from 'markdown-to-jsx'
import './MarkdownRenderer.css'

const MarkdownRenderer = ({content, options = {}}) => {
  return (
    <Markdown className="md-renderer-body" options={options} children={content}></Markdown>
  )
}

export default MarkdownRenderer