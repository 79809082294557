import { HttpMethods } from "./HttpMethods.js";
import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider.js";
export class Auth {
  static async login(email, password) {
    const data = { email, password };
    const response = await HttpMethods.request_post("/v1/auth/login", data);
    if (!response) addErrorMessageGlobal("Error al hacer login");
    return response;
  }
  static async getToken() {
    const response = await HttpMethods.request_get("/v1/auth/gettoken");
    if (!response)
      addErrorMessageGlobal("Error al obtener token de autentificacion");
    return response;
  }

  static async oauth2(data) {
    const response = await HttpMethods.request_post("/v1/auth/oauth2", data);
    addErrorMessageGlobal("Error al obtener token con OAuth2");
    return response;
  }

  static async getPolicies() {
    const response = await HttpMethods.request_get("/v1/auth/policies");
    addErrorMessageGlobal("Error al obtener las politicas");
    return response;
  }
}
