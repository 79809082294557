import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import Message from "../components/global/Message/Message";

const MessageContext = createContext();

let _addMessageGlobal = null;
let _addErrorMessageGlobal = null;
// Variable a nivel de módulo. Permitirá que HttpMethods
// la invoque aunque no sea un componente React.

function formatDate(date) {
  const pad = (num) => String(num).padStart(2, "0");
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const SESSION_STORAGE_KEY = "notifications";

export function MessageProvider({ children }) {
  const loadInitialMessages = () => {
    const storedMessages = sessionStorage.getItem(SESSION_STORAGE_KEY);
    return storedMessages ? JSON.parse(storedMessages) : [];
  };

  // Mensajes persistentes en sessionStorage
  const [persistentQueue, setPersistentQueue] = useState(loadInitialMessages);
  // Mensajes en pantalla
  const [displayQueue, setDisplayQueue] = useState([]);
  // Mensaje que se está mostrando actualmente
  const [currentMessage, setCurrentMessage] = useState(null);

  useEffect(() => {
    sessionStorage.setItem(
      SESSION_STORAGE_KEY,
      JSON.stringify(persistentQueue)
    );
  }, [persistentQueue]);

  // Efecto: Manejar la cola de mensajes en pantalla
  useEffect(() => {
    if (!currentMessage && displayQueue.length > 0) {
      const [nextMessage, ...rest] = displayQueue;
      setCurrentMessage(nextMessage);
      setDisplayQueue(rest);
    }
  }, [currentMessage, displayQueue]);

  // Efecto: Ocultar mensaje actual después de 2.5 segundos
  useEffect(() => {
    let timeout;
    if (currentMessage) {
      timeout = setTimeout(() => {
        setCurrentMessage(null);
      }, 2500);
    }
    return () => clearTimeout(timeout);
  }, [currentMessage]);

  /**
   * Agregar mensaje a las colas
   * @param {Object} message Objeto con el mensaje a agregar.
   */
  const enqueueMessage = (message) => {
    setPersistentQueue((prev) => [...prev, message]);
    setDisplayQueue((prev) => [...prev, message]);
  };

  /**
   * Función para encolar mensajes "normales" (type: "info").
   * @param {string} message Texto del mensaje.
   */
  const addMessage = useCallback((message) => {
    enqueueMessage({
      id: uuidv4(),
      text: message,
      type: "info",
      timeGeneratedAt: formatDate(new Date()),
    });
  }, []);

  /**
   * Función para encolar mensajes de error (type: "error").
   * @param {string} error Texto del mensaje de error.
   */
  const addErrorMessage = useCallback((error) => {
    enqueueMessage({
      id: uuidv4(),
      text: error,
      type: "error",
      timeGeneratedAt: formatDate(new Date()),
    });
  }, []);

  _addMessageGlobal = addMessage;
  _addErrorMessageGlobal = addErrorMessage;

  return (
    <MessageContext.Provider
      value={{
        addMessage,
        addErrorMessage,
        persistentQueue,
        setPersistentQueue,
      }}
    >
      {children}

      {/* Render del mensaje actual (si existe) */}
      {currentMessage && (
        <Message type={currentMessage.type}>{currentMessage.text}</Message>
      )}
    </MessageContext.Provider>
  );
}

/**
 * @typedef {Object} MessageContextValue
 * @property {(message: string) => void} addMessage - Agrega mensaje de tipo "info"
 * @property {(error: string) => void} addErrorMessage - Agrega mensaje de tipo "error"
 * @property {Array} persistentQueue - Cola de mensajes persistentes
 */

/**
 * Retorna las funciones para encolar mensajes de info y error
 * @returns {MessageContextValue}
 */
export default function useMessages() {
  return useContext(MessageContext);
}

// Función auxiliar para usar en módulos "no React"
export function addMessageGlobal(msg) {
  if (!_addMessageGlobal) {
    // Si el provider no se ha montado aún, puedes decidir
    // encolar en algún buffer o simplemente hacer un console.error
    console.warn("MessageProvider no está montado todavía");
    return;
  }
  _addMessageGlobal(msg);
}

// Función auxiliar para usar en módulos "no React"
export function addErrorMessageGlobal(msg) {
  if (!_addErrorMessageGlobal) {
    // Si el provider no se ha montado aún, puedes decidir
    // encolar en algún buffer o simplemente hacer un console.error
    console.warn("MessageProvider no está montado todavía");
    return;
  }
  _addErrorMessageGlobal(msg);
}

MessageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
