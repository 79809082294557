import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Doc {
  static async list(framecode, first = 0, pagination = 100) {
    let response = await HttpMethods.request_get(
      `/v1/doc/list/-/${framecode}/${first}/${pagination}`
    );
    if (!response) {
      addErrorMessageGlobal("Error al obtener los documentos");
      response = [];
    }
    return response;
  }

  static async listflat(framecode, first = 0, pagination = 100) {
    const response = await HttpMethods.request_get(
      `/v1/doc/list/-/${framecode}/${first}/${pagination}?format=flat`
    );
    if (!response) {
      addErrorMessageGlobal("Error al obtener los documentos");
    }
    return response;
  }

  static async graph(dossiercode) {
    const response = await HttpMethods.request_get(
      `/v1/doc/graph/${dossiercode}`,
      "text"
    );
    return response;
  }

  static async short(short, framecode, skip, limit, dossierCode, format) {
    let response = await HttpMethods.request_get(`/v1/doc/list?format=flat`);
    if (!response) {
      addErrorMessageGlobal("Error al obtener los documentos");
      response = [];
    }
    return response;
  }

  static async dossier(dossierCode) {
    let response = await HttpMethods.request_get(
      `/v1/doc/dossier/${dossierCode}`
    );
    if (!response) {
      addErrorMessageGlobal("Error al obtener los documentos");
      response = [];
    }
    return response;
  }

  static async status(docCode, data) {
    let response = await HttpMethods.request_post(
      `/v1/doc/status/${docCode}`,
      data
    );
    if (!response) {
      addErrorMessageGlobal("Error al actualizar el estado del documento");
      response = [];
    }
    return response;
  }

  static async request(docCode, data = {}) {
    let response = await HttpMethods.request_post(
      `/v1/doc/request/${docCode}`,
      data
    );
    if (!response) {
      addErrorMessageGlobal("Error al realizar la solicitud del documento");
      response = [];
    }
    return response;
  }

  static async received(docCode, data = {}) {
    let response = await HttpMethods.request_post(
      `/v1/doc/received/${docCode}`,
      data
    );
    if (!response) {
      addErrorMessageGlobal("Error al marcar el documento como recibido");
      response = [];
    }
    return response;
  }

  static async read(docCode) {
    let response = await HttpMethods.request_get(`/v1/doc/read/${docCode}`);
    if (!response) {
      addErrorMessageGlobal("Error al obtener el documento");
      response = {};
    }
    return response;
  }
}
