import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@monaco-editor/react";
import "./NotesEditor.css";
import EditorButton from "./EditorButton";
import PopoverMenu from "./PopoverMenu/PopoverMenu";
import constants from "src/definitions/constants";
import ThemeOption from "./PopoverMenu/ThemeOption";

const NoteEditor = ({ content, onClose, onChange }) => {
  const options = constants.stickyNoteThemes;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const buttonRef = useRef(null);
  const viewportRef = useRef(null)
  
  useEffect(() => {
    viewportRef.current = document.querySelector(".react-flow__viewport");
  }, []);

  const onClick = (name, value) => (event) => {
    onChange(name)(value)
    setIsPopoverOpen(false)
  }

  return (
    <div className="notes-node-editor">
        <PopoverMenu
          containerRef={viewportRef}
          triggerRef={buttonRef}
          isOpen={isPopoverOpen}
        >
          {options.map((option, index) => (
            <PopoverMenu.Option onClick={(e) => onClick('theme', option.id)(e)}>
              <ThemeOption option={option}></ThemeOption>
            </PopoverMenu.Option>
          ))}
        </PopoverMenu>
      <div className="notes-node-editor-header nodrag">
        <EditorButton
          id="theme-button"
          icon="palette"
          onClick={() => {
            setIsPopoverOpen((last) => !last);
          }}
          ref={buttonRef}
        />
        <EditorButton id="close-button" icon="close" onClick={onClose} />
      </div>
      <Editor
        options={{ minimap: { enabled: false } }}
        defaultLanguage="markdown"
        value={content}
        onChange={onChange("content")}
        theme="vs-dark"
        className="nodrag"
        width={"100%"}
        minHeight={"calc(100% - 35px)"}
        maxHeight={"100%"}
      />
    </div>
  );
};

export default NoteEditor;
