import React, { useEffect, useState } from "react";
import "./Agentes.css";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import Select from "src/modules/components/common/Select/Select";
import constants from "src/definitions/constants";
import TextArea from "src/modules/components/common/Text Area/TextArea";
import Chat from "../Chat/Chat";
import Icon from "@mui/material/Icon";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const tiposAgentes = Object.entries(constants.tiposAgentes).map(
  ([, value]) => ({ value: value.value, label: value.label })
);

const Agentes = () => {
  const { setupData: setupInfo, setSetupData: setSetupInfo } = useAppContext();
  const [agentes, setAgentes] = useState(setupInfo?.agents || []);

  const [currentAgent, setCurrentAgent] = useState(null);

  // Guarda el contenido editable del agente seleccionado (si estamos en "modalType = EDIT_AGENT" o "ADD_AGENT")
  const [editedAgent, setEditedAgent] = useState(null);

  // Controla la apertura del modal (sea para editar/agregar agentes o subir archivos)
  const [openBox, setOpenBox] = useState(false);

  // Indica qué está mostrando el modal: "ADD_AGENT", "EDIT_AGENT" o "UPLOAD_FILES"
  const [modalType, setModalType] = useState(null);

  // Para mostrar/ocultar el chat
  const [showChat, setShowChat] = useState(false);

  // Actualiza la data en el contexto global
  useEffect(() => {
    setSetupInfo((prev) => ({ ...prev, agents: agentes }));
  }, [agentes, setSetupInfo]);

  // Atajo para saber si estamos agregando (ADD_AGENT)
  const isNewAgent = modalType === "ADD_AGENT";

  // Función de reseteo rápido de estados relacionados con el modal
  const resetModal = () => {
    setOpenBox(false);
    setEditedAgent(null);
    setCurrentAgent(null);
    setModalType(null);
  };

  const onCloseChat = () => {
    setShowChat(false);
    setCurrentAgent(null);
  };

  // === Lógica para EDITAR/AGREGAR ===
  const handleAgenteClick = (index) => {
    const agenteSeleccionado = agentes[index];
    setCurrentAgent(agenteSeleccionado);
    setEditedAgent({ ...agenteSeleccionado });
    setModalType("EDIT_AGENT");
    setOpenBox(true);
  };

  const handleEditAgente = (e) => {
    setEditedAgent((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const agregarElemento = () => {
    const newItem = {
      id: crypto.randomUUID(),
      name: "",
      description: "",
      type: "",
      apikey: "",
      agentid: "",
      systemprompt: "",
      url: "",
      model: "",
    };
    setCurrentAgent(newItem);
    setEditedAgent(newItem);
    setModalType("ADD_AGENT");
    setOpenBox(true);
  };

  const handleSaveAgente = () => {
    if (isNewAgent) {
      setAgentes((prev) => [...prev, editedAgent]);
    } else {
      const updatedAgentes = agentes.map((a) =>
        a === currentAgent ? editedAgent : a
      );
      setAgentes(updatedAgentes);
    }
    resetModal();
  };

  const handleDeleteAgente = () => {
    setAgentes(agentes.filter((a) => a !== currentAgent));
    resetModal();
  };

  function onSelectChangeSingle(event, setValues, property) {
    setValues((prev) => ({ ...prev, [property]: event?.value }));
  }

  const onClickExit = () => resetModal();

  // === Definimos el título del modal ===
  const boxTitle =
    modalType === "UPLOAD_FILES"
      ? "Agregar archivos"
      : isNewAgent
      ? "Agregar Agente"
      : "Editar Agente";

  // === Pie del BoxFloat (solo para "ADD_AGENT" / "EDIT_AGENT") ===
  const footerBoxFloat = (
    <div className="agente-container-buttons">
      {(modalType === "ADD_AGENT" || modalType === "EDIT_AGENT") && (
        <>
          <Button icon="save" onClick={handleSaveAgente} />
          {!isNewAgent && <Button icon="delete" onClick={handleDeleteAgente} />}
        </>
      )}
    </div>
  );

  return (
    <div className="agentes-container">
      {openBox && (
        <BoxFloat
          title={boxTitle}
          onClickExit={onClickExit}
          onClose={currentAgent ? onClickExit : undefined}
          footer={footerBoxFloat}
        >
          {/* Agregar / Editar Agente */}
          {(modalType === "ADD_AGENT" || modalType === "EDIT_AGENT") && (
            <>
              <Input
                name="name"
                type="text"
                onChange={handleEditAgente}
                value={editedAgent?.name || ""}
                label="Nombre"
              />
              <Input
                name="description"
                type="text"
                onChange={handleEditAgente}
                value={editedAgent?.description || ""}
                label="Descripción"
              />
              <Select
                name="type"
                options={tiposAgentes}
                onChange={(event) =>
                  onSelectChangeSingle(event, setEditedAgent, "type")
                }
                label="Tipo"
                value={tiposAgentes.find(
                  (tipo) => tipo.value === editedAgent?.type
                )}
              />

              {/* OPEN API Agent */}
              {editedAgent?.type === constants.tiposAgentes.openai.value && (
                <>
                  <Input
                    name="apikey"
                    type="text"
                    onChange={handleEditAgente}
                    value={editedAgent?.apikey || ""}
                    label="API Key"
                  />
                  <Input
                    name="agentid"
                    type="text"
                    onChange={handleEditAgente}
                    value={editedAgent?.agentid || ""}
                    label="Agent ID"
                  />
                </>
              )}

              {/* OPEN LLM */}
              {editedAgent?.type === constants.tiposAgentes.openllm.value && (
                <>
                  <Input
                    name="url"
                    type="text"
                    placeholder="http://192.168.40.90:11434/api/chat"
                    onChange={handleEditAgente}
                    value={editedAgent?.url || ""}
                    label="URL"
                  />
                  <Input
                    name="model"
                    type="text"
                    placeholder="llama3"
                    onChange={handleEditAgente}
                    value={editedAgent?.model || ""}
                    label="Modelo"
                  />
                  <TextArea
                    name="systemprompt"
                    onBlur={handleEditAgente}
                    defaultValue={editedAgent?.systemprompt || ""}
                    label="System Prompt"
                    styleTextArea={{ minHeight: "80px" }}
                  />
                </>
              )}
            </>
          )}
        </BoxFloat>
      )}

      <div className="display-agentes">
        {agentes?.map((agente, i) => (
          <div
            key={agente.id || i}
            className="agente-item"
            onClick={() => handleAgenteClick(i)}
          >
            <div className="agente-item-title">{agente.name}</div>
            <div className="agente-item-description">{agente.description}</div>
            <div className="agente-item-iconsInLine">
              {/* <div
                className="agente-item-uploadFiles"
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentAgent(agente);
                  setModalType('UPLOAD_FILES');
                  setOpenBox(true);
                }}
              >
                <Icon>upload_file</Icon>
              </div> */}
              <div
                className="agente-item-chatIcon"
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentAgent(agente);
                  setShowChat(true);
                }}
              >
                <Icon>chaticon</Icon>
              </div>
            </div>
          </div>
        ))}
        <div
          className="agente-item agente-item-add-last"
          onClick={agregarElemento}
        >
          <p>Agregar más</p>
        </div>
      </div>

      {/* CHAT */}
      {showChat && currentAgent && (
        <div className="agente-chat-box">
          <Chat agente={currentAgent} onClose={onCloseChat} />
        </div>
      )}
    </div>
  );
};

export default Agentes;
