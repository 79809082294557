import { useMemo, useCallback } from 'react';

export function useMustache() {
  // Memoizamos getNestedValue ya que es una función helper que no cambia
  const getNestedValue = useCallback((obj, path) => {
    return path.split('.').reduce((acc, key) => {
      if (acc && typeof acc === 'object') {
        const arrayMatch = key.match(/(\w+)\[(\d+)\]/);
        if (arrayMatch) {
          const arrayKey = arrayMatch[1];
          const index = parseInt(arrayMatch[2], 10);
          return acc[arrayKey] && Array.isArray(acc[arrayKey]) ? acc[arrayKey][index] : "";
        }
        return key in acc ? acc[key] : "";
      }
      return "";
    }, obj);
  }, []);

  // Memoizamos replaceMustache
  const replaceMustache = useCallback((text, variables, params = null) => {
    if (!text || typeof text !== 'string' || !variables) return "";
    const re = /{{([^}]+)}}/g;

    return text.replace(re, function (match, p1) {
      const subvars = p1.split(".");
      if (subvars[0] === "params") {
        return params.get(subvars[1]);
      }
      return variables[p1] ?? getNestedValue(variables, p1);
    });
  }, [getNestedValue]);

  // Memoizamos editMustacheValue
  const editMustacheValue = useCallback((path, value, obj) => {
    const parts = path.replace(/\{|\}/g, "").split(".");
    const updatedObject = { ...obj };

    let current = updatedObject;

    for (let i = 0; i < parts.length - 1; i++) {
      const isArrayAccess = /\[(\d+)\]/.exec(parts[i]);

      if (isArrayAccess) {
        const arrayIndex = parseInt(isArrayAccess[1]);
        const arrayName = parts[i].replace(/\[\d+\]/, "");

        if (Array.isArray(current[arrayName])) {
          current = current[arrayName];
          current = current[arrayIndex];
        } else {
          return obj;
        }
      } else {
        if (parts[i] in current) {
          current = current[parts[i]];
        } else {
          return obj;
        }
      }
    }

    const lastPart = parts[parts.length - 1];
    const isArrayAccess = /\[(\d+)\]/.exec(lastPart);
    const arrayName = lastPart.replace(/\[\d+\]/, "");

    if (
      isArrayAccess &&
      arrayName in current &&
      Array.isArray(current[arrayName])
    ) {
      const arrayIndex = parseInt(isArrayAccess[1]);
      current[arrayName][arrayIndex] = value;
    } else {
      current[lastPart] = value;
    }

    return updatedObject;
  }, []);

  // Memoizamos el objeto retornado para mantener una referencia estable
  return useMemo(() => ({
    replaceMustache,
    editMustacheValue
  }), [replaceMustache, editMustacheValue]);
}