import { NodeResizer } from "@xyflow/react";
import React, { memo, useState } from "react";
import "../../planner.css";
import "./NotesNode.css";
import MarkdownRenderer from "../../../MarkdownRenderer/MarkdownRenderer";
import NoteEditor from "./NoteEditor/NoteEditor";
import constants from "src/definitions/constants";

const NotesNode = ({ data, selected, id }) => {
  const themes = constants.stickyNoteThemes.map(theme => theme.id)

  const [openEditor, setOpenEditor] = useState(false);

  const onChange = (name) => (event) => {
    data.onChange(name, id)(event)
  }


  const onDoubleClick = (event) => {
    event.preventDefault()
    setOpenEditor(true)
  }

  const onCloseEditor = (event) => {
    setOpenEditor(false)
  }

  return (
    <div
      data-theme={themes.includes(data.theme) ? data.theme : 'default'}
      className="notes-node-container"
      onDoubleClick={onDoubleClick}
      id={id}
    >
      <NodeResizer
        isVisible={selected}
        minWidth={100}
        minHeight={30}
      />
      {openEditor && (
        <NoteEditor content={data.content} onClose={onCloseEditor} onChange={onChange}/>
      )}
      <MarkdownRenderer content= {data.content}/>
    </div>
  );
};

export default memo(NotesNode);
