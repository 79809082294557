import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Info {
  /**
   * Obtiene la información básica, con la opción de usar el formato plano.
   * @param {boolean} formatFlat - Si es true, agrega `?format=flat` a la solicitud.
   * @returns {Promise<any>} - Respuesta del servidor.
   */
  static async basics(formatFlat = false) {
    let url = "/v1/info/basics";
    if (formatFlat) url += "?format=flat";

    const response = await HttpMethods.request_get(url);
    if (!response) {
      addErrorMessageGlobal("Error al obtener la información básica");
    }
    return response;
  }

  /**
   * Obtiene la información de estado.
   * @returns {Promise<any>} - Respuesta del servidor.
   */
  static async status() {
    const response = await HttpMethods.request_get("/v1/info/status");
    if (!response) {
      addErrorMessageGlobal("Error al obtener la información de estado");
    }
    return response;
  }
}
