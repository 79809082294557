import Input from "src/modules/components/common/Input/Input";
import "./template.css";
import Button from "src/modules/components/common/Button/Button";
import { Template as TemplateAPI } from "src/lib/api";
import { Filetype as FiletypeAPI } from "src/lib/api";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Editor } from "@monaco-editor/react";
import Iframe from "src/modules/components/common/Iframe/Iframe";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import { default as Select } from "src/modules/components/common/Select/Select";

function Template() {
  const [datacode, setdatacode] = useState("_first_");
  const [renderTemplateValues, setRenderTemplateValues] = useState("");
  const [templateInfo, setTemplateInfo] = useState({ design: "", content: "" });
  const [device, setDevice] = useState("desktop");
  const [screenState, setScrenState] = useState("render");
  const location = useLocation();
  const navigate = useNavigate();
  const {setNavBarTitle } = useAppContext();
  const [fileTypeOptions, setFileTypeOptions] = useState([]);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const parametroTemplateCode = queryParams.get("templatecode");
    if (parametroTemplateCode) {
      TemplateAPI.read(parametroTemplateCode).then((res) => {
        setTemplateInfo(res);
        setNavBarTitle(`Editando Plantilla ${res.name}`);
        if (res.scope === "filetype") {
          fetchFileTypes();
        }
      });
    }
  }, []);
  
  const fetchFileTypes = async () => {
    const fileTypesFromAPI = await FiletypeAPI.list(); 
    // (Asumiendo que la API retorna un arreglo de {value, label})
    const formattedFileTypes = fileTypesFromAPI.map((fileType) => ({
      value: fileType.name,
      label: fileType.name,
    }));

    setFileTypeOptions(formattedFileTypes || []);
    if ((formattedFileTypes || []).length > 0) {
      setdatacode(formattedFileTypes[0].value);
    }
    
  };

  useEffect(() => {
    console.log(screenState);
  }, [screenState]);
  const onClickRenderTemplate = () => {
    if (datacode === "") {
      return setRenderTemplateValues("Ingrese el datacode");
    }
    TemplateAPI.test(
      datacode,
      templateInfo.design,
      templateInfo.content,
      templateInfo.scope
    ).then((res) => {
      console.log(res);
      if (res === undefined) {
        return setRenderTemplateValues(
          "Verifique que el codigo del dossier existe"
        );
      }
      setRenderTemplateValues(res);
    });
  };
  const onClickSaveTemplate = () => {
    if (datacode === "") return setRenderTemplateValues("Ingrese el datacode");
    if (templateInfo.templatecode === undefined)
      return alert(
        "Vuelva a la pantalla de templates y seleccione un template"
      );
    const template = {
      active: templateInfo.active,
      description: templateInfo.description,
      design: templateInfo.design,
      name: templateInfo.name,
      scope: templateInfo.scope,
      content: templateInfo.content,
      templatecode: templateInfo.templatecode,
      view: templateInfo.view,
    };
    TemplateAPI.update(template);
  };
  const onClickBackToTemplates = () => {
    navigate(`/template/edit?templatecode=${templateInfo.templatecode}`);
  };
  const onClickoutrender = () => {
    window.open(
      `/view/template?templatecode=${templateInfo.templatecode}&datacode=${datacode}`,
      "valeriadevelop"
    );
  };

  function createHtml(html, device, templateInfo, datacode) {
    const url = `/view/template?templatecode=${templateInfo.templatecode}&datacode=${datacode}`;
    console.log("html", screenState);
    return (
      <>
        {screenState === "render" ? (
          <div
            className={`render-screen-${device}`}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        ) : (
          // <div className={`render-screen-${device}`}>
          <Iframe
            src={url}
            title="Template preview"
            frameBorder="0"
            allowFullScreen
            className={`render-screen-${device}`}
          />
          // </div>
        )}
      </>
    );
  }
  const buttonScreenState = () => {
    setScrenState(screenState === "render" ? "iframe" : "render");
  };
  const onClickEstructuraTemplate = () => {
    if (datacode === "") {
      return setRenderTemplateValues("Ingrese el datacode");
    }
    TemplateAPI.testAuto(
      datacode,
      templateInfo.design,
      templateInfo.content,
      templateInfo.scope
    ).then((res) => {
      console.log(res);
      if (res === undefined) {
        return setRenderTemplateValues(
          "Verifique que el codigo del dossier existe"
        );
      }
      setRenderTemplateValues(res);
    });
  };
  const onClickAutoTemplate = () => {
    if (datacode === "") {
      return setRenderTemplateValues("Ingrese el datacode");
    }
    TemplateAPI.testAutoTemplate(
      datacode,
      templateInfo.design,
      templateInfo.content,
      templateInfo.scope
    ).then((res) => {
      console.log(res);
      if (res === undefined) {
        return setRenderTemplateValues(
          "Verifique que el codigo del dossier existe"
        );
      }
      setRenderTemplateValues(res);
    });
  };

  return (
    <PanelGroup direction="horizontal">
      <div className="templateScreen">
        <div className="templateScreen-containers">
          <Panel>
          <div className="edit-template__buttons">
            <Button
              icon="arrow_back"
              onClick={onClickBackToTemplates}
            />
            <div className="templateScreen-panel-scope">
              {templateInfo.scope}:
            </div>
            {templateInfo.scope === "filetype" ? (
              <Select
                name="datacode"
                isSearchable={false}
                isClearable={false}
                options={fileTypeOptions}
                onChange={(option) => setdatacode(option.value)}
                value={fileTypeOptions.find((opt) => opt.value === datacode)}
              />
            ) : (
              <Input
                type="text"
                placeholder="Ingrese el datacode"
                onChange={(e) => {
                  setdatacode(e.target.value);
                }}
                value={datacode}
                className="templateScreen-input"
              />
            )}
            <Button
              content="AutoTemplate"
              onClick={onClickAutoTemplate}
              style={{ minWidth: "20px" }}
            />
            <Button
              content="Estructura"
              onClick={onClickEstructuraTemplate}
              style={{ minWidth: "20px" }}
            />
            <Button
              content="Render"
              onClick={onClickRenderTemplate}
              style={{ minWidth: "20px" }}
            />
            <Button
              icon="save"
              onClick={onClickSaveTemplate}
            />
          </div>
            <Editor
              defaultLanguage="html"
              value={templateInfo.design}
              onChange={(value) =>
                setTemplateInfo((prev) => {
                  return { ...prev, design: value };
                })
              }
              theme="vs-dark"
            />
          </Panel>
          <PanelResizeHandle className="resizer" />
          <Panel>
          <div className="edit-template__buttons">
            <Button
              icon={
                screenState === "render" ? "Toggle_Off_Icon" : "Toggle_On_Icon"
              }
              onClick={buttonScreenState}
            />
            <Button
              icon="Laptop"
              onClick={() => {
                setDevice("desktop");
              }}
              className={device === "desktop" ? "--active" : ""}
            />
            <Button
              icon="Smartphone"
              onClick={() => {
                setDevice("mobile");
              }}
              className={device === "mobile" ? "--active" : ""}
            />
            <Button
              icon="directions_alt"
              onClick={() => {
                onClickoutrender();
              }}
            />
          </div>
            <div className="renderTemplate-container">
              {createHtml(renderTemplateValues, device, templateInfo, datacode)}
            </div>
          </Panel>
        </div>
      </div>
    </PanelGroup>
  );
}

export default Template;
