import React, { useEffect, useState } from 'react';
import './Chat.css';
import Button from 'src/modules/components/common/Button/Button';
import { Agent } from 'src/lib/api';
import Icon from '@mui/material/Icon';
import constants from 'src/definitions/constants';
import PropTypes from 'prop-types';

const Chat = ({ agente, onClose }) => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [loading, setLoading] = useState(false);
  const [settingsData, setSettingsData] = useState({
    type: agente.type,
  });

  useEffect(() => {
    if (agente.type === constants.tiposAgentes.openai.value) {
      setSettingsData((prev) => {
        return {
          ...prev,
          agentid: agente.id,
          systemprompt: agente.systemprompt,
        };
      });
    } else if (agente.type === constants.tiposAgentes.openllm.value) {
      setSettingsData((prev) => {
        return {
          ...prev,
          agentid: agente.id,
          model: agente.model,
          url: agente.url,
          systemprompt: agente.systemprompt,
        };
      });
    }
  }, [agente]);

  const handleUserInput = (e) => {
    setInputText(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 /*Intro*/) {
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    if (inputText.trim() === '') return;
    if (loading) return;
    const messagesToSend = [...messages];
    const firstMessage = { text: inputText, role: 'user' };
    messagesToSend.push(firstMessage);
    setMessages(messagesToSend);

    setInputText('');
    setLoading(true);

    let response;
    try {
      let messageReply;
      if (settingsData.type === constants.tiposAgentes.openai.value) {
        response = await Agent.question(
          settingsData.agentid,
          messagesToSend,
          settingsData.threadid
        );
        if (response.message) {
          //El error lo devuelve en la prop mensaje
          messagesToSend.push({ text: response.message, role: 'agent' });
          setMessages(messagesToSend);
          return;
        }
        setSettingsData((prev) => {
          return { ...prev, threadid: response.agent.agent.thread };
        });
        messageReply = response.agent.agent.message;
      } else if (settingsData.type === constants.tiposAgentes.openllm.value) {
        response = await Agent.question(settingsData.agentid, messagesToSend);
        messageReply = response?.agent.agent.message;
      }
      messagesToSend.push({ text: messageReply, role: 'agent' });
      setMessages(messagesToSend);
    } catch (error) {
      console.error('Error fetching data from the API:', error);
      messagesToSend.push({
        text: 'Error fetching data from the API',
        role: 'agent',
      });
      setMessages(messagesToSend);
    } finally {
      setLoading(false);
    }
  };

  const handleClearChat = () => {
    setMessages([]);
    if (settingsData.type === 'openai') delete settingsData.threadID;
  };

  const handleOnCloseChat = () => {
    onClose();
  };

  return (
    <div className="container-chat">
      <h2> {agente['name']}</h2>
      <div className="chat-close-button-div" onClick={handleOnCloseChat}>
        <Icon>close</Icon>
      </div>

      <div className="chat-app">
        <div className="chat-window">
          {messages.map((message, index) => (
            <div
              key={index}
              className={
                message.role === 'user' ? 'user-message' : 'agent-message'
              }
            >
              {message.text}
            </div>
          ))}
          {loading && (
            <div className="loading-animation">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          )}
        </div>
      </div>
      <div className="input-container-chat">
        <input
          type="text"
          value={inputText}
          onChange={handleUserInput}
          placeholder="Type your message..."
          onKeyDown={handleKeyDown}
          className="input-container-chat-input"
        />
        <div className="input-container-chat-buttons">
          <Button
            onClick={handleClearChat}
            content={<Icon> comments_disabled_icon</Icon>}
            style={{ minWidth: '20px', marginLeft: '10px' }}
            title="Clear chat"
          />
          <Button
            onClick={handleSendMessage}
            content={<Icon>send</Icon>}
            style={{ minWidth: '20px' }}
            title="Send"
          />
        </div>
      </div>
    </div>
  );
};

Chat.propTypes = {
  agente: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    systemprompt: PropTypes.string,
    model: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Chat;
