// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notes-node-editor {
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  position: absolute;
  width: -webkit-fill-available;
}

.notes-node-editor-header {
  display: flex;
  background-color: #1e1e1e;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-bottom: -1px;
}

.notes-node-editor-header > #close-button:hover {
    background-color: rgb(209, 10, 10);
    border-top-right-radius: 4px;
    transition: all 300ms;
}

.notes-node-editor-header > #theme-button:hover {
  background-color: rgb(82, 82, 82);
  border-top-left-radius: 4px;
  transition: all 300ms;
}`, "",{"version":3,"sources":["webpack://./src/modules/components/common/Planner/Nodes/NotesNode/NoteEditor/NotesEditor.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,aAAa;EACb,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,2BAA2B;EAC3B,4BAA4B;EAC5B,mBAAmB;AACrB;;AAEA;IACI,kCAAkC;IAClC,4BAA4B;IAC5B,qBAAqB;AACzB;;AAEA;EACE,iCAAiC;EACjC,2BAA2B;EAC3B,qBAAqB;AACvB","sourcesContent":[".notes-node-editor {\r\n  height: -webkit-fill-available;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: flex-end;\r\n  padding: 15px;\r\n  position: absolute;\r\n  width: -webkit-fill-available;\r\n}\r\n\r\n.notes-node-editor-header {\r\n  display: flex;\r\n  background-color: #1e1e1e;\r\n  border-top-left-radius: 4px;\r\n  border-top-right-radius: 4px;\r\n  margin-bottom: -1px;\r\n}\r\n\r\n.notes-node-editor-header > #close-button:hover {\r\n    background-color: rgb(209, 10, 10);\r\n    border-top-right-radius: 4px;\r\n    transition: all 300ms;\r\n}\r\n\r\n.notes-node-editor-header > #theme-button:hover {\r\n  background-color: rgb(82, 82, 82);\r\n  border-top-left-radius: 4px;\r\n  transition: all 300ms;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
