import {
  addErrorMessageGlobal,
  addMessageGlobal,
} from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Comment {
  static async create(comment) {
    const response = await HttpMethods.request_post(
      "/v1/comment/create",
      comment
    );
    if (response.error) {
      addErrorMessageGlobal(
        `Error al crear el structure.\nError: ${response.error}: ${response.message}`
      );
    }
    if (response.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response;
  }

  static async reply(reply, commentid) {
    const response = await HttpMethods.request_post(
      `/v1/comment/reply/${commentid}`,
      reply
    );
    if (response.error) {
      addErrorMessageGlobal(
        `Error al crear el structure.\nError: ${response.error}: ${response.message}`
      );
    }
    if (response.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response;
  }

  static async list(scope, datacode, pointer, limit) {
    const response = await HttpMethods.request_get(
      `/v1/comment/list/${scope}/${datacode}/${pointer}/${limit}`
    );
    if (!response) {
      addErrorMessageGlobal("Error al obtener los comentarios");
    }
    return response;
  }
}
