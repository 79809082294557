import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "src/modules/components/common/Button/Button";
import { Cartridge as CartridgeAPI } from "src/lib/api";
import { validateProperties } from "src/lib/validateProperties";
import FormContainer from "src/modules/components/Containers/FormContainer/FormContainer";
import Tabs from "src/modules/components/header/Tabs/Tabs";
import CartridgeTab from "./tabs/CartridgeTab";
import ChecksTab from "./tabs/ChecksTab";
import PlannerTab from "./tabs/PlannerTab";

import "./Cartridge.css";

function Cartridge() {
  const navigate = useNavigate();
  const [cartridge, setCartridge] = useState();
  const buildPlannerRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const parametroCartridgeCode = queryParams.get("cartridgecode");

  const validateCartridge = () => {
    const propertiesToValidate = {
      cartridgecode: cartridge.cartridgecode,
      name: cartridge.name,
    };
    const errorMessage = validateProperties(propertiesToValidate);
    return errorMessage === ""
      ? { validated: true }
      : { validated: false, errorMessage };
  };

  const saveCartridge = () => {
    const validation = validateCartridge();
    if (!validation.validated) return alert(validation.errorMessage);
    const cartridgeToUpload = cartridge;
    cartridgeToUpload.planner = buildPlannerRef.current();
    CartridgeAPI.crupdate(cartridgeToUpload);
    setCartridge(cartridgeToUpload);
  };

  const deleteCartridge = () => {
    const confirmation = window.confirm(
      `¿Seguro que quieres eliminar el cartucho: ${cartridge.cartridgecode}?`
    );

    if (confirmation) {
      CartridgeAPI.delete(cartridge.cartridgecode);
    }
  };

  const redirectCartridges = () => {
    navigate("/view/dcartridges");
  };

  const tabsData = [
    {
      name: "Cartucho",
      id: "cartridge",
      component: (
        <CartridgeTab cartridge={cartridge} setCartridge={setCartridge} />
      ),
    },
    {
      name: "Checks",
      component: <ChecksTab />,
      id: "checks",
    },
    {
      name: "Planificador",
      component: (
        <PlannerTab
          cartridge={cartridge}
          setBuildPlanner={(fn) => (buildPlannerRef.current = fn)}
        />
      ),
      id: "planner",
    },
  ];

  return (
    <FormContainer style={{ margin: "10px", padding: "24px" }}>
      <Tabs
        tabsData={tabsData}
        defaultIndex={queryParams.has("tab") ? queryParams.get("tab") : 0}
      />
      <div className="css-175oi2c" style={{ alignItems: "center" }}>
        <Button id="cartuchos" onClick={redirectCartridges} icon="arrow back" />
        <Button id="createCartridge" onClick={saveCartridge} icon="save" />
        {parametroCartridgeCode && (
          <Button
            id="deleteCartridge"
            onClick={deleteCartridge}
            icon="delete"
          />
        )}
      </div>
    </FormContainer>
  );
}

export default Cartridge;
