import React from "react";
import PropTypes from "prop-types";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import "./Action.css";
import Select from "src/modules/components/common/Select/Select";
import Collapsible from "src/modules/components/common/Collapsible/Collapsible";
import { Icon } from "@mui/material";
const Action2 = ({ action, onDelete, onChange }) => {
  const actionsType = [{ value: "error", label: "error", icon: "error" }];

  const handleDelete = () => {
    onDelete(action);
  };
  const handleInputChange = (event) => {
    const updatedAction = { ...action };
    updatedAction[event.target.name] = event.target.value;
    onChange(updatedAction);
  };

  const onSelectActionTypeChange = (event) => {
    const updatedAction = { ...action };
    updatedAction.type = event ? event.value : "";
    onChange(updatedAction);
  };
  return (
    <Collapsible
      header={
        <div className="array-header">
          <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {action.name === "" ? (
              "Nueva Acción"
            ) : action.type ? (
              <Icon>
                {actionsType.find((act) => act.value === action.type).icon}
              </Icon>
            ) : (
              ""
            )}
            <span>{action.name}</span>
          </span>

          <Button icon="delete" onClick={handleDelete} />
        </div>
      }
    >
      {" "}
      <div className="action-container">
        <Input
          type="text"
          name="name"
          value={action.name}
          onChange={handleInputChange}
          placeholder="Name"
          buttonStyle={{ pointerEvents: "none" }}
          label="Nombre"
        />
        <Select
          isSearchable={true}
          isClearable={true}
          name="selectActionType"
          options={actionsType}
          placeholder="Type"
          onChange={onSelectActionTypeChange}
          label="Tipo"
          value={actionsType.find((option) => {
            if (option.value === action.type) return option;
          })}
        />
        <Input
          type="text"
          name="problem"
          value={action.problem}
          onChange={handleInputChange}
          placeholder="Problem"
          label="Problema"
        />
        <Input
          type="text"
          name="solution"
          value={action.solution}
          onChange={handleInputChange}
          placeholder="Solution"
          label="Solución"
        />
        <Input
          type="text"
          name="link"
          value={action.link}
          onChange={handleInputChange}
          placeholder="Link"
          label="Link"
        />
        <Input
          type="text"
          name="helpLink"
          value={action.helpLink}
          onChange={handleInputChange}
          placeholder="Help Link"
          label="Link de ayuda"
        />
        <Input
          type="text"
          name="for"
          value={action.for}
          onChange={handleInputChange}
          placeholder="For"
          label="Responsable"
        />
      </div>
    </Collapsible>
  );
};

Action2.propTypes = {
  action: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    problem: PropTypes.string,
    solution: PropTypes.string,
    link: PropTypes.string,
    helpLink: PropTypes.string,
    for: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Action2;
