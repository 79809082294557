// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grupos-container-buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  justify-content: center;
}
.agentes-container {
  .box-float {
    max-width: 650px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/routes/Setup/Grupos/Grupos/Grupos.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".grupos-container-buttons {\r\n  display: flex;\r\n  gap: 20px;\r\n  margin-bottom: 10px;\r\n  justify-content: center;\r\n}\r\n.agentes-container {\r\n  .box-float {\r\n    max-width: 650px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
