// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notifications__header {
  border-bottom: 1px solid var(--detail-color);
  margin-bottom: 15px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_notification_icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.header_notification_icon-count {
  position: absolute;
  top: -6px;
  right: -6px;
  background: var(--warning-color);
  color: var(--font-color);
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notifications__header__title {
  font-weight: bold;
}

.notifications__header__clearbutton {
  font-size: small;
  cursor: pointer;
}
.notifications__items {
  max-height: 80vh;
  overflow-y: auto;
}

.no-notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 10px;
  margin-bottom: 15px;
  gap: 10px;
}

/* .no-notifications-icon {

}
*/
.no-notifications-text {
  font-size: small;
}
`, "",{"version":3,"sources":["webpack://./src/modules/components/header/NavBar/Notifications/Notifications.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;EAC5C,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,gCAAgC;EAChC,wBAAwB;EACxB,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,SAAS;AACX;;AAEA;;;CAGC;AACD;EACE,gBAAgB;AAClB","sourcesContent":[".notifications__header {\r\n  border-bottom: 1px solid var(--detail-color);\r\n  margin-bottom: 15px;\r\n  padding: 5px 10px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n.header_notification_icon {\r\n  position: relative;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  cursor: pointer;\r\n}\r\n.header_notification_icon-count {\r\n  position: absolute;\r\n  top: -6px;\r\n  right: -6px;\r\n  background: var(--warning-color);\r\n  color: var(--font-color);\r\n  border-radius: 50%;\r\n  font-size: 10px;\r\n  font-weight: bold;\r\n  width: 16px;\r\n  height: 16px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n.notifications__header__title {\r\n  font-weight: bold;\r\n}\r\n\r\n.notifications__header__clearbutton {\r\n  font-size: small;\r\n  cursor: pointer;\r\n}\r\n.notifications__items {\r\n  max-height: 80vh;\r\n  overflow-y: auto;\r\n}\r\n\r\n.no-notifications {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  padding: 5px 10px;\r\n  margin-bottom: 15px;\r\n  gap: 10px;\r\n}\r\n\r\n/* .no-notifications-icon {\r\n\r\n}\r\n*/\r\n.no-notifications-text {\r\n  font-size: small;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
