import React, { useEffect, useState } from "react";
import "./User.css";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import Select from "src/modules/components/common/Select/Select";
import DoubleContainer from "src/modules/components/Containers/DoubleContainer/DoubleContainer";
import FormContainer from "src/modules/components/Containers/FormContainer/FormContainer";
import Policies from "src/modules/components/common/Policies/Policies";
import DevicesTable from "./DevicesTable/DevicesTables";
import { Setup as SetupAPI, User as UserAPI } from "src/lib/api";
import { useLocation, useNavigate } from "react-router-dom";

const userDefaultValues = {
  name: "",
  email: "",
  password: "",
  policies: [],
  role: "user",
  active: true,
  devicecheck: false,
  activity: true,
  presencecontrol: false,
  devices: [],
  groups: [],
};
const validateInputFilled = (inputValues) => {
  const requiredFields = ["name", "email", "policies", "role"];
  for (let field of requiredFields) {
    if (!inputValues[field]) {
      alert(`Falta ${field}`);
      return false;
    }
  }
  return true;
};
const User = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isUserInQuerry, setIsUserInQuerry] = useState(false);
  const [userValues, setUserValues] = useState(userDefaultValues);
  const [roles, setRoles] = useState([]);
  const [groupsOptions, setGroupsOptions] = useState([]);

  useEffect(() => {
    async function fetchRolesAndGroups() {
      const setup = await SetupAPI.read();
      const rolesFromSetup = setup.roles.map((rol) => ({
        label: rol.name,
        value: rol.name,
        policies: rol.policies,
      }));

      const groupsFromSetup = setup.groups.map((group) => ({
        label: group.name,
        value: group.name,
      }));

      setRoles(rolesFromSetup);
      setGroupsOptions(groupsFromSetup);
    }
    fetchRolesAndGroups();
  }, []);

  useEffect(() => {
    async function fetchUser(email) {
      const user = await UserAPI.read(email);
      setUserValues((prevState) => ({
        ...prevState,
        name: user.name,
        email: user.email,
        policies: user.policies,
        role: user.role ?? "",
        active: user.active ?? "",
        devicecheck: user.devicecheck ?? "",
        devices: user.devices ?? [],
        activity: user.activity || false,
        presencecontrol: user.presencecontrol || false,
        groups: user.groups ?? [],
      }));
    }
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    if (email) {
      fetchUser(email);
      setIsUserInQuerry(true);
    }
  }, [location.search]);

  // useEffect(() => {
  //   const newPolicies = roles.find(
  //     (rol) => rol.value === userValues.role
  //   ).policies;
  //   setUserValues((prev) => {
  //     return {
  //       ...prev,
  //       policies: newPolicies,
  //     };
  //   });
  // }, [userValues.role, roles]);
  const handleInputChange = (event) => {
    const { name, type, checked, value } = event.target;
    setUserValues((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleRoleChange = (event) => {
    const selectedRole = event.value;
    const rolePolicies =
      roles.find((role) => role.value === selectedRole)?.policies || [];
    setUserValues((prevState) => ({
      ...prevState,
      role: selectedRole,
      policies: rolePolicies,
    }));
  };

  const setPolicies = (event) => {
    setUserValues((prev) => {
      return {
        ...prev,
        policies: event,
      };
    });
  };

  const handleCreateUser = () => {
    if (!validateInputFilled(userValues)) return;
    if (!userValues.password) {
      alert("Falta la contraseña");
      return;
    }
    UserAPI.create(userValues);
  };

  const handleUpdateUser = () => {
    const data = { ...userValues };
    if (!userValues.password) delete data.password;
    UserAPI.update(data);
  };

  const handleDeleteUser = () => {
    const confirmation = window.confirm(
      `¿Seguro que quieres eliminar al usuario: ${userValues.email}?`
    );
    if (confirmation) {
      UserAPI.delete(userValues.email);
    }
  };

  const editDevice = (id, permited) => {
    const updatedDevices = userValues.devices.map((device) =>
      device.id === id ? { ...device, permited } : device
    );
    setUserValues((prevState) => ({ ...prevState, devices: updatedDevices }));
  };

  const handleGroupsChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((opt) => opt.value)
      : [];
    setUserValues((prevState) => ({
      ...prevState,
      groups: selectedValues,
    }));
  };
  return (
    <DoubleContainer>
      <FormContainer>
        <div className="css-175oi2c" style={{ alignItems: "center" }}>
          <Button
            id="usuarios"
            onClick={() => navigate("/view/users")}
            icon="arrow_back"
          />
          {!isUserInQuerry && (
            <Button
              id="createUser"
              onClick={handleCreateUser}
              content="Crear Usuario"
              buttonStyle={{ maxWidth: "200px" }}
            />
          )}
          {isUserInQuerry && (
            <>
              <Button id="updateUser" onClick={handleUpdateUser} icon="save" />
              <Button
                id="deleteUser"
                onClick={handleDeleteUser}
                icon="delete"
              />
            </>
          )}
        </div>
        <Input
          name="name"
          type="text"
          placeholder="Nombre de usuario"
          value={userValues.name}
          onChange={handleInputChange}
          label="Nombre de usuario"
          autocomplete="off"
        />
        <Input
          name="email"
          type="text"
          placeholder="Email"
          value={userValues.email}
          onChange={handleInputChange}
          label="Correo Electrónico"
          autocomplete="off"
        />
        <Input
          name="password"
          type="password"
          placeholder="Contraseña"
          value={userValues.password}
          onChange={handleInputChange}
          label="Contraseña"
          autocomplete="new-password"
        />
        <Select
          isSearchable
          isClearable={false}
          name="role"
          options={roles}
          placeholder="Roles..."
          onChange={handleRoleChange}
          value={roles?.find((role) => role.value === userValues.role)}
          label="Rol"
        />
        <Select
          isMulti
          isSearchable
          name="groups"
          options={groupsOptions}
          placeholder="Grupos..."
          onChange={handleGroupsChange}
          value={groupsOptions.filter((group) =>
            userValues.groups.includes(group.value)
          )}
          label="Grupos"
        />
        <Input
          name="active"
          type="checkbox"
          checked={userValues.active}
          onChange={handleInputChange}
          label="Active"
        />
        <Input
          name="devicecheck"
          type="checkbox"
          checked={userValues.devicecheck}
          onChange={handleInputChange}
          label="Validación de dispositivos"
        />
        <Input
          name="activity"
          type="checkbox"
          checked={userValues.activity}
          onChange={handleInputChange}
          label="Control de actividad"
        />
        <Input
          name="presencecontrol"
          type="checkbox"
          checked={userValues.presencecontrol}
          onChange={handleInputChange}
          label="Control Fisico"
        />
        <Policies
          policies={userValues.policies}
          setPolicies={(event) => setPolicies(event)}
        />
      </FormContainer>
      <FormContainer
        style={{
          minHeight: "500px",
          justifyContent: "normal",
          paddingTop: "28px",
        }}
      >
        <DevicesTable
          inputValues={userValues.devices}
          editDevice={editDevice}
        />
      </FormContainer>
    </DoubleContainer>
  );
};

export default User;
