import { useEffect } from "react";
import constants from "src/definitions/constants";
import { colorByType, getId } from "./helper";

const DEFAULT_NODES = []

const useReconstructPlanner = (initialNodes = DEFAULT_NODES, setNodes, setEdges, onChange) => {
  const reconstructNode = (node) => {
    const isNote = node.type === "note";

    const reconstructNodeDataByType = (node) => {
      const typeHandlers = {
        createdoc: () => ({
          filename: node.createdoc?.filename ?? "",
          filetype: node.createdoc?.filetype ?? "",
          format: node.createdoc?.format ?? "",
          template: node.createdoc?.template ?? "",
        }),
        document: () => ({
          filename: node.document?.filename ?? "",
          filetype: node.document?.filetype ?? "",
          requestlimit: node.document?.requestlimit ?? "",
          documentlimit: node.document?.documentlimit ?? "",
          must: node.document?.must ?? false,
          who: node.document?.who ?? { label: "", value: "" },
        }),
        action: () => ({
          conductorname: node.action?.conductorname ?? "",
        }),
        event: () => ({
          fixdays: node.event?.fixdays ?? "",
          eventtype: node.event?.eventtype ?? { label: "", value: "" },
          alerttext: node.event?.alerttext ?? "",
          alertdays: node.event?.alertdays ?? "",
        }),
        variable: () => ({
          variables: node.info ?? [],
        }),
        ai: () => ({
          agent: node.ai?.agent ?? { label: "", value: "" },
          prompt: node.ai?.prompt ?? "",
          variable: node.ai?.variable ?? "",
        }),
        note: () => ({
          ...node.note,
          onChange,
        }),
      };

      return typeHandlers[node.type]?.() ?? node;
    };

    const baseNode = {
      id: node.id,
      selected: false,
      data: {
        label: isNote ? undefined : node.name,
        type: node.type,
        name: isNote ? undefined : node.name,
        logic: isNote ? undefined : node.logic,
        ...reconstructNodeDataByType(node),
      },
      position: {
        x: node.pos.x,
        y: node.pos.y,
      },
      type: isNote
        ? constants.nodeByType.stickyNote
        : constants.nodeByType.plannerNode,
      className: node?.logic ? "border-logic" : "base-border-node",
      style: isNote
        ? {
            width: node.note?.width || null,
            height: node.note?.height || null,
          }
        : {
            backgroundColor: colorByType(node.type),
            zIndex: 1,
          },
    };

    return baseNode;
  };

  const reconstructEdge = (source, target) => ({
    id: getId(),
    source,
    target,
    data: {
      label: "",
      interactionWidth: 1000,
    },
    type: "customEdge",
  });

  useEffect(() => {
    if (initialNodes.length <= 0) return;

    const reconstructedNodes = initialNodes.map(reconstructNode);

    const reconstructedEdges = initialNodes
      .filter((node) => node.type !== "note")
      .flatMap((node) =>
        node.depends.map((dependencyId) =>
          reconstructEdge(dependencyId, node.id)
        )
      );

    setNodes(reconstructedNodes);
    setEdges(reconstructedEdges);
  }, [initialNodes, setNodes, setEdges]);
};

export default useReconstructPlanner;
