import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Filetype {
  static async read(filetypename) {
    const response = await HttpMethods.request_get(
      `/v1/filetype/read/${filetypename}`
    );
    return response;
  }

  static async rename(filetypename, newfiletypename) {
    const response = await HttpMethods.request_get(
      `/v1/filetype/rename/${filetypename}/${newfiletypename}`
    );
    return response;
  }

  static async duplicate(filetypename, newfiletypename) {
    const response = await HttpMethods.request_get(
      `/v1/filetype/duplicate/${filetypename}/${newfiletypename}`
    );
    return response;
  }

  static async empty(filetypename) {
    const response = await HttpMethods.request_get(
      `/v1/filetype/empty/${filetypename}`
    );
    return response;
  }

  static async crupdate(filetype) {
    const response = await HttpMethods.request_post(
      `/v1/filetype/crupdate`,
      filetype
    );
    if (!response) {
      addErrorMessageGlobal(`Error al guardar el filetype ${filetype.name}`);
    }
    return response;
  }

  static async view(frametype, filetype) {
    const response = await HttpMethods.request_get(
      `/v1/filetype/view/${frametype}/${filetype}`
    );
    if (!response) {
      addErrorMessageGlobal(
        `Error al generar el view ${frametype} para el filetype ${filetype.name}`
      );
    }
    return response;
  }

  static async list() {
    const response = await HttpMethods.request_get(`/v1/filetype/list`);
    if (!response) {
      addErrorMessageGlobal(`Error al listar los filetypes`);
    }
    return response;
  }

  static async assign(path) {
    const response = await HttpMethods.request_get(
      `/v1/filetype/assign/${path}`
    );
    return response;
  }
}
