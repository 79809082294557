import "src/modules/components/common/MetaWindow/DossierDashboard/DossierDashboardMenu/DossierDashboardMenu.css";
import React, { useEffect } from "react";
import PropTypes from "prop-types"; // Importa PropTypes
import Tooltip from "@mui/material/Tooltip";
import SvgIcon from "@mui/material/SvgIcon";
import CommentIcon from "@mui/icons-material/Comment";
import FolderIcon from "@mui/icons-material/Folder";
import HistoryIcon from "@mui/icons-material/History";
import EuroIcon from "@mui/icons-material/Euro";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DescriptionIcon from "@mui/icons-material/Description";
import EmailIcon from "@mui/icons-material/Email";
import ChatIcon from "@mui/icons-material/Chat";
const StructureMenu = ({ selectedMenuItem, dispatch, menuItemRefs }) => {
  const handleMenuItemClick = (item) => {
    dispatch({
      type: "SET_SELECTED_MENU_ITEM",
      payload: item,
    });
  };

  useEffect(() => {
    const activeElement = menuItemRefs.current[selectedMenuItem];
    if (activeElement) {
      const activeIndicator = document.querySelector(
        ".dossier-dashboard-menu__active-indicator"
      );
      if (activeIndicator) {
        activeIndicator.style.width = `${activeElement.offsetWidth}px`;
        activeIndicator.style.left = `${activeElement.offsetLeft}px`;
      }
    }
  }, [selectedMenuItem, menuItemRefs]);

  const menuOptions = [
    { name: "Flows", icon: CompareArrowsIcon },
    { name: "Comentarios", icon: CommentIcon },
    { name: "Archivos", icon: FolderIcon },
    { name: "Logs", icon: HistoryIcon },
    { name: "Movimientos", icon: EuroIcon },
    { name: "Documentos", icon: DescriptionIcon },
    { name: "Comunicaciones", icon: EmailIcon },
    { name: "Chat", icon: ChatIcon },
  ];

  return (
    <ul className="dossier-dashboard-menu__list">
      {menuOptions.map((menuItem) => (
        <li
          key={menuItem.name}
          ref={(el) => (menuItemRefs.current[menuItem.name] = el)}
          className={`dossier-dashboard-menu__item ${
            selectedMenuItem === menuItem.name
              ? "dossier-dashboard-menu__item--active"
              : ""
          }`}
          onClick={() => handleMenuItemClick(menuItem.name)}
        >
          <Tooltip title={menuItem.name}>
            <SvgIcon component={menuItem.icon} />
          </Tooltip>
        </li>
      ))}
    </ul>
  );
};

// Validación de props
StructureMenu.propTypes = {
  selectedMenuItem: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  menuItemRefs: PropTypes.shape({
    current: PropTypes.objectOf(
      PropTypes.shape({
        offsetWidth: PropTypes.number,
        offsetLeft: PropTypes.number,
      })
    ),
  }).isRequired,
};

export default StructureMenu;
