import {
  addErrorMessageGlobal,
  addMessageGlobal,
} from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Cartridge {
  static async public() {
    const response = await HttpMethods.request_get("/v1/cartridge/public");
    if (!response) addErrorMessageGlobal("Error al obtener los cartridges");
    return response?.cartridges;
  }

  static async list() {
    const response = await HttpMethods.request_get("/v1/cartridge/list");
    if (!response) addErrorMessageGlobal("Error al obtener los cartridges");
    return response?.cartridges;
  }

  static async listflat(first = 0, pagination = 100) {
    let response = await HttpMethods.request_get(
      `/v1/cartridge/list?format=flat`
    );
    if (!response) {
      addErrorMessageGlobal("Error al obtener los cartuchos");
      response = [];
    }
    return response;
  }

  static async read(cartridgeCode) {
    const cartridge = encodeURIComponent(cartridgeCode);
    const response = await HttpMethods.request_get(
      `/v1/cartridge/read/${cartridge}`
    );
    if (!response) addErrorMessageGlobal("Error al obtener el cartridge");
    return response?.cartridge;
  }

  static async create(cartridgeCode) {
    const response = await HttpMethods.request_post(
      "/v1/cartridge/create",
      cartridgeCode
    );
    if (response.error) {
      addErrorMessageGlobal(
        `Error al crear el cartridge.\nError: ${response.error}: ${response.message}`
      );
    }
    if (response.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response;
  }

  static async update(cartridgeCode) {
    const response = await HttpMethods.request_post(
      "/v1/cartridge/update",
      cartridgeCode
    );
    if (response.error) {
      addErrorMessageGlobal(
        `Error al actualizar el cartridge.\nError: ${response.error}: ${response.message}`
      );
    }
    if (response.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response;
  }

  static async crupdate(cartridgeCode) {
    const response = await HttpMethods.request_post(
      "/v1/cartridge/crupdate",
      cartridgeCode
    );
    if (response.error) {
      addErrorMessageGlobal(
        `Error al crear el cartridge.\nError: ${response.error}: ${response.message}`
      );
    }
    if (response.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response;
  }

  static async delete(cartridgeCode) {
    const cartridge = encodeURI(cartridgeCode);
    const response = await HttpMethods.request_delete(
      "/v1/cartridge/delete",
      cartridge
    );
    if (response.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response;
  }
}
