import React from "react";
import DefaultSidebarItems from "../DefaultSidebarItems";
import Input from "../../../Input/Input";

const ActionNodeSidebar = ({ data, onChange }) => {
  return (
    <>
      <DefaultSidebarItems data={data} onChange={onChange} />
      <Input
        type="text"
        name="conductorname"
        value={data.conductorname}
        label="Id en conductor"
        onChange={onChange("conductorname")}
      />
    </>
  );
};

export default ActionNodeSidebar;
