import React, { useState } from "react";
import Button from "src/modules/components/common/Button/Button";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import Select from "src/modules/components/common/Select/Select";
import ISO3166 from "src/definitions/ISO3166.json";
import { Setup as SetupAPI, Bank as BankAPI } from "src/lib/api";
import Input from "src/modules/components/common/Input/Input";
import constants from "src/definitions/constants.js";
import "./BancosStyles.css";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const Bancos = () => {
  const {
    setIsAccountSet,
    setupData: setupInfo,
    setSetupData: setSetupInfo,
  } = useAppContext();
  const [institutions, setInstitutions] = useState([]);
  const [boxNewBank, setBoxNewBank] = useState(false);
  const [boxPeriodicityMovements, setBoxPeriodicityMovements] = useState(false);
  const [selectedBank, setSelectedBank] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [peridocity, setPeridocity] = useState("");
  const countryOptions = ISO3166.map((country) => ({
    value: country.iso,
    label: country.country,
  }));
  const periodicityOptions = Object.entries(constants.periodicity).map(
    ([key, value]) => ({
      value: value.value,
      label: value.label,
    })
  );

  const getInstitutions = async (e) => {
    const institutions = await BankAPI.bankList({ country: e.value });
    setInstitutions(institutions);
  };
  const addBank = () => {
    setBoxNewBank(true);
    return;
  };
  const saveNewBank = (e) => {
    setBoxNewBank(false);
    if (
      selectedBank?.bank === undefined ||
      selectedBank?.country === undefined
    ) {
      return;
    }
    const getLogicaSetup = async () => {
      const bankFound = institutions.find(
        (institution) => institution.id === selectedBank.bank
      );
      const setupTemp = {
        ...setupInfo,
        bank: {
          ...setupInfo.bank,
          accounts: [
            ...setupInfo.bank.accounts,
            {
              bank: bankFound?.name,
              institutionid: bankFound?.id,
              bic: bankFound?.bic,
              countries: selectedBank?.country,
              logo: bankFound?.logo,
              transactiontotaldays: bankFound?.transaction_total_days,
              active: false,
              status: "CR",
            },
          ],
        },
      };
      const resultSetupAPI = await SetupAPI.write(setupTemp);
      return resultSetupAPI;
    };
    getLogicaSetup().then((result) => {
      setSetupInfo(result);
    });
  };

  const openBoxPeriodicityMovements = (e) => {
    setBoxPeriodicityMovements(true);
    return;
  };
  const savePeriodicity = (e) => {
    setBoxPeriodicityMovements(false);
    setSetupInfo((prev) => {
      const setupTemp = {
        ...prev,
        bank: {
          ...prev.bank,
          periodicity: peridocity,
        },
      };
      SetupAPI.write(setupTemp);
      return setupTemp;
    });
  };

  const deleteBank = (row) => {
    const newSetup = { ...setupInfo };
    newSetup.bank.accounts = newSetup.bank.accounts.filter(
      (account) => account.id !== row.id
    );
    if (row.name) {
      BankAPI.bankDelete(row.id).then((res) => {
        newSetup.bank.accounts = newSetup.bank.accounts.filter(
          (account) => account.id !== row.id
        );
        setSetupInfo(newSetup);
        SetupAPI.write(newSetup);
      });
    } else {
      setSetupInfo(newSetup);
      SetupAPI.write(newSetup);
    }
  };
  const columns = [
    {
      field: "active",
      headerName: "Activo",
      type: "checkbox",
      width: 20,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "bank",
      headerName: "Banco",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "cuenta",
      headerName: "Cuenta",
      type: "string",
      width: 300,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "name",
      headerName: "Descripción",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "ownername",
      headerName: "Propietario",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "status",
      headerName: "Estado",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "lastaccess",
      headerName: "Fecha Ultimo Acceso",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: "Acciones",
      type: "buttons",
      width: 200,
      align: "left",
      headerAlign: "left",
      elements: [
        {
          type: "button",
          content: "Editar",
          onClick: (e) => deleteBank(e),
          icon: "delete",
        },
      ],
    },
  ];

  return (
    <div>
      {boxNewBank && (
        <BoxFloat
          title="Añadir Banco"
          onClickExit={() => setBoxNewBank(false)}
          footer={<Button icon="save" onClick={saveNewBank} />}
        >
          <Select
            isSearchable={true}
            name="country"
            options={countryOptions}
            placeholder="Spain"
            onChange={(event) => {
              if (event === null) {
                return;
              }
              setSelectedBank((prev) => {
                return {
                  ...prev,
                  country: event.value,
                };
              });
              getInstitutions(event);
            }}
            label="Pais"
          />
          <Select
            isSearchable={true}
            name="banks"
            options={institutions?.map((institution) => ({
              value: institution.id,
              label: institution.name,
            }))}
            placeholder="Banco"
            onChange={(event) => {
              if (event === null) {
                return;
              }
              setSelectedBank((prev) => {
                return {
                  ...prev,
                  bank: event.value,
                };
              });
            }}
            label="Bancos"
          />
        </BoxFloat>
      )}
      {boxPeriodicityMovements && (
        <BoxFloat
          title="Periodicidad de los movimientos"
          onClickExit={() => setBoxPeriodicityMovements(false)}
          footer={<Button icon="save" onClick={savePeriodicity} />}
        >
          <Select
            name="peridocity"
            options={periodicityOptions}
            placeholder="Ninguno"
            onChange={(event) => {
              if (event === null) {
                return;
              }
              setPeridocity(event.value);
            }}
            label="Tiempo"
          />
        </BoxFloat>
      )}
      <div
        className="buttons-bancos"
        style={{
          display: "flex",
          gap: "20px",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <Button content="Añadir" onClick={addBank} />
        <Button
          content="Periodicidad movimientos"
          onClick={openBoxPeriodicityMovements}
        />
        <Button
          content={isLoading ? "Cargando..." : "Actualizar cuentas"}
          onClick={async () => {
            setIsLoading(true);
            const result = await BankAPI.bankRequisitions();
            if (result) {
              SetupAPI.read().then((setup) => {
                setSetupInfo(setup);
                setIsLoading(false);
              });
            } else {
              setIsLoading(false);
            }
          }}
        />
        <Button
          content="Keys"
          onClick={() => setIsAccountSet((prev) => !prev)}
        />
      </div>
      <table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.field}
                style={{ width: column.width, textAlign: column.align }}
              >
                {column.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <Body
            rows={setupInfo.bank.accounts}
            columns={columns}
            setupInfo={setupInfo}
            setSetupInfo={setSetupInfo}
          />
        </tbody>
      </table>
    </div>
  );
};

const Body = ({ rows, columns, setupInfo, setSetupInfo }) => {
  const onClickRevalidar = (row) => {
    //POST para pedir que se revalide la cuenta
    BankAPI.bankRevalidation(row.id).then((res) => {
      console.log(res);
      const link = res.link;
      //Se redirige a la página de la entidad bancaria para revalidar la cuenta
      window.open(link, "_blank");
    });
  };
  const handleCheckboxChange = (event, row) => {
    // Crear una copia de setupInfo
    const newSetupInfo = { ...setupInfo };
    // Generar un ID si la fila no tiene uno
    if (!row._id) {
      row._id = generateUUID();
    }
    // Encontrar la fila correspondiente y actualizar su propiedad 'active'
    const rowIndex = newSetupInfo.bank.accounts.findIndex(
      (account) => account._id === row._id
    );
    if (rowIndex !== -1) {
      newSetupInfo.bank.accounts[rowIndex].active = event.target.checked;
    }

    // Actualizar el estado
    setSetupInfo(newSetupInfo);
  };

  const renderCell = (column, row) => {
    if (column.type === "checkbox") {
      return (
        <Input
          type="checkbox"
          value={row[column.field]}
          onChange={(event) => handleCheckboxChange(event, row)}
          styleInputContainer={{ justifyContent: "center" }}
        />
      );
    } else if (column.field === "bank" && row.logo) {
      return (
        <div
          className="value_and_logo"
          style={{ display: "flex", gap: "15px", alignItems: "center" }}
        >
          <img src={row.logo} alt={row.banco} style={{ maxHeight: "30px" }} />
          <p>{row[column.field]}</p>
        </div>
      );
    } else if (
      column.field === "cuenta" &&
      row.status !== "CR" &&
      (!row.accountid || row.accountid === "")
    ) {
      return (
        <div className="divlink" onClick={() => onClickRevalidar(row)}>
          Revalidar
        </div>
      );
    } else if (column.field === "cuenta" && row.validationlink) {
      return (
        <a href={row.validationlink} target="_blank" rel="noopener noreferrer">
          Validación
          {row[column.field]}
        </a>
      );
    } else if (column.field === "cuenta") {
      return row.iban;
    } else if (column.field === "actions" && row.validationlink) {
      return (
        <div style={{ justifyContent: "center", display: "flex" }}>
          {column.elements.map((element, index) => (
            <Button
              onClick={(e) => {
                element.onClick(row);
              }}
              key={index}
              icon={element.icon}
              buttonStyle={{
                minWidth: "40px",
                maxWidth: "50px",
                maxHeight: "100px",
                minHeight: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          ))}
        </div>
      );
    } else {
      return row[column.field];
    }
  };
  let currentColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--bg-color")
    .trim();
  let nextColor = "rgba(0, 0, 0, 0.2)";

  return rows.map((row, index) => {
    // Check if row status is in the specified list
    if (
      ["CR", "ERROR", "UA", "RJ", "SA", "GA", "LN", "EX"].includes(row.status)
    ) {
      // If it is, switch the color
      let temp = currentColor;
      currentColor = nextColor;
      nextColor = temp;
    }

    return (
      <tr
        key={index}
        className={`row_${index}`}
        style={{ backgroundColor: currentColor }}
      >
        {columns.map((column, index) => (
          <td
            key={column.field}
            style={{ textAlign: column.align }}
            className={`cell_${index}`}
          >
            {renderCell(column, row)}
          </td>
        ))}
      </tr>
    );
  });
};
function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default Bancos;
