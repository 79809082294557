import React from "react";
import DefaultSidebarItems from "../DefaultSidebarItems";
import Input from "../../../Input/Input";
import Select from "../../../Select/Select";

export const CreateDocNodeSidebar = ({ data, onChange, templateOptions }) => {
  const formatOptions = [
    { label: "PDF", value: "pdf" },
    { label: "Original", value: "raw" },
    { label: "Word", value: "docx" },
    { label: "Excel", value: "xlsx" },
  ];

  return (
    <>
      <DefaultSidebarItems data={data} onChange={onChange} />
      <Input
        type="text"
        name="filetype"
        value={data.filetype}
        label="Tipo de documento"
        onChange={onChange("filetype")}
        placeholder="CN, DNI, CD, Pasaporte..."
      />
      <Input
        type="text"
        name="filename"
        value={data.filename}
        label="Nombre del archivo"
        onChange={onChange("filename")}
        placeholder="CN de {{dossier.file.name}}"
      />
      <Select
        name="format"
        value={formatOptions.find((opt) => opt.value === data.format) ?? null}
        label="Formato"
        onChange={onChange("format")}
        options={formatOptions}
      />
      <Select
        name="template"
        value={
          templateOptions.find((opt) => opt.value === data.template) ?? null
        }
        label="Plantilla"
        onChange={onChange("template")}
        options={templateOptions}
      />
    </>
  );
};

export default CreateDocNodeSidebar;
