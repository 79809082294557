import { useState } from "react";

export const useSubmenuVisibility = () => {
  const [showSubmenu, setShowSubmenu] = useState(false);

  const handleMouseEnter = (hasSubmenus) => {
    if (hasSubmenus) setShowSubmenu(true);
  };

  const handleMouseLeave = () => setShowSubmenu(false);

  return { showSubmenu, handleMouseEnter, handleMouseLeave };
};
