import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Assistant {
  static async list() {
    const response = await HttpMethods.request_get("/v1/assistant/list");
    if (!response)
      addErrorMessageGlobal("Error al obtener la lista de asistentes");
    return response;
  }

  static async sendEmail(assitantName, to, subject) {
    const data = { assitantName, to, subject };
    const response = await HttpMethods.request_post(
      "/v1/assitant/sendemail",
      data
    );
    if (!response) addErrorMessageGlobal("Error al enviar el email");
    return response;
  }

  static async listEmail(assitantName) {
    const response = await HttpMethods.request_get(
      "/v1/assitant/listemail" + assitantName
    );
    if (!response)
      addErrorMessageGlobal(
        "Error al obtener la lista de emails del asistente"
      );
    return response;
  }

  static async readEmail(assitantName, messageId) {
    const response = await HttpMethods.request_get(
      "/v1/assitant/reademail" + assitantName + "/" + messageId
    );
    if (!response)
      addErrorMessageGlobal("Error al leer el email del asistente");
    return response;
  }
}
