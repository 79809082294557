import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import "./Navbar.css";
import { useSubmenuVisibility } from "./hooks/useSubmenuVisibility";

const Menu = ({ val, isLastItem }) => {
  const { role, logout } = useAppContext();
  const { showSubmenu, handleMouseEnter, handleMouseLeave } =
    useSubmenuVisibility();

  const handleLogout = () => {
    if (val.url === "/logout") logout();
  };

  const renderSubmenu = () =>
    val.submenus?.map((submenu) => {
      const canAccess = role && submenu.roles.includes(role) && submenu.active;
      if (!canAccess) return null;

      return (
        <ul key={submenu.id}>
          <NavLink
            id={window.location.pathname === submenu.url ? "active" : ""}
            to={submenu.url}
            target={submenu.outwindow ? "_blank" : "_self"}
            onClick={() => submenu.url === "/logout" && logout()}
          >
            {submenu.title}
          </NavLink>
        </ul>
      );
    });

  return (
    <div
      className="submenu-container"
      onMouseEnter={() => handleMouseEnter(val.submenus)}
      onMouseLeave={handleMouseLeave}
    >
      <NavLink
        id={window.location.pathname === val.url ? "active" : ""}
        to={val.url}
        target={val.outwindow ? "_blank" : "_self"}
        onClick={handleLogout}
      >
        <div id="title">
          {val.title === "usermenu" ? (
            <SvgIcon component={PersonIcon} />
          ) : (
            val.title
          )}
        </div>
      </NavLink>
      {showSubmenu && (
        <li className={`submenu ${isLastItem ? "lastitem" : ""}`}>
          {renderSubmenu()}
        </li>
      )}
    </div>
  );
};

Menu.propTypes = {
  val: PropTypes.shape({
    url: PropTypes.string.isRequired,
    outwindow: PropTypes.bool,
    title: PropTypes.string.isRequired,
    submenus: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        outwindow: PropTypes.bool,
        title: PropTypes.string.isRequired,
        roles: PropTypes.arrayOf(PropTypes.string),
        active: PropTypes.bool,
      })
    ),
  }).isRequired,
  isLastItem: PropTypes.bool,
};

export default Menu;
