import React from "react";
import constants from "src/definitions/constants";
import Select from "../../Select/Select";
import Input from "../../Input/Input";
import TextArea from "../../Text Area/TextArea";

const DefaultSidebarItems = ({data, onChange}) => {
  return (
    <>
      <Select
        onChange={onChange("type")}
        name="type"
        value={
          Object.values(constants.nodeTypes).find(
            (constant) => constant.value === data.type
          ) || null
        }
        placeholder="Tipo"
        options={Object.values(constants.nodeTypes).filter(
          (opt) => opt.type === constants.nodeByType.plannerNode
        )}
        label="Tipo"
      />
      <Input
        type="text"
        name="name"
        value={data.name}
        label="Nombre"
        onChange={onChange("name")}
      />
      <TextArea
        name="logic"
        value={data.logic}
        label="Lógica"
        onChange={onChange("logic")}
      />
      <hr
        style={{
          border: "1px dashed #fff",
          width: "-webkit-fill-available",
        }}
      />
    </>
  );
};

export default DefaultSidebarItems;
