// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, 50%);
  padding: 1rem;
  background: var(--bg-color);
  font-weight: 900;
  font-size: 1.2rem;

  animation: fadeInOutMessage 2.5s ease-in-out forwards;
  z-index: 9999;
}
.message--info {
  background: var(--bg-color);
  border: 3px solid var(--detail-color);
}

.message--error {
  background: var(--bg-color);
  border: 3px solid var(--error-color);
}

@keyframes fadeInOutMessage {
  0% {
    transform: translate(-50%, 50%);
    opacity: 0;
  }
  15% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  85% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 50%);
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/components/global/Message/Message.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,SAAS;EACT,+BAA+B;EAC/B,aAAa;EACb,2BAA2B;EAC3B,gBAAgB;EAChB,iBAAiB;;EAEjB,qDAAqD;EACrD,aAAa;AACf;AACA;EACE,2BAA2B;EAC3B,qCAAqC;AACvC;;AAEA;EACE,2BAA2B;EAC3B,oCAAoC;AACtC;;AAEA;EACE;IACE,+BAA+B;IAC/B,UAAU;EACZ;EACA;IACE,gCAAgC;IAChC,UAAU;EACZ;EACA;IACE,gCAAgC;IAChC,UAAU;EACZ;EACA;IACE,+BAA+B;IAC/B,UAAU;EACZ;AACF","sourcesContent":[".message {\r\n  position: fixed;\r\n  bottom: 1rem;\r\n  left: 50%;\r\n  transform: translate(-50%, 50%);\r\n  padding: 1rem;\r\n  background: var(--bg-color);\r\n  font-weight: 900;\r\n  font-size: 1.2rem;\r\n\r\n  animation: fadeInOutMessage 2.5s ease-in-out forwards;\r\n  z-index: 9999;\r\n}\r\n.message--info {\r\n  background: var(--bg-color);\r\n  border: 3px solid var(--detail-color);\r\n}\r\n\r\n.message--error {\r\n  background: var(--bg-color);\r\n  border: 3px solid var(--error-color);\r\n}\r\n\r\n@keyframes fadeInOutMessage {\r\n  0% {\r\n    transform: translate(-50%, 50%);\r\n    opacity: 0;\r\n  }\r\n  15% {\r\n    transform: translate(-50%, -50%);\r\n    opacity: 1;\r\n  }\r\n  85% {\r\n    transform: translate(-50%, -50%);\r\n    opacity: 1;\r\n  }\r\n  100% {\r\n    transform: translate(-50%, 50%);\r\n    opacity: 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
