import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { default as Picker } from "react-best-gradient-color-picker";
import "./ColorPicker.css";

const ColorPicker = ({ value, onChange, label = "" }) => {
  const ref = useRef(null);
  const [color, setColor] = useState("#ffffff");
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setColor(value);
  }, [value]);

  return (
    <div
      className="input-container"
      onClick={() => setShowPicker(true)}
      ref={ref}
    >
      {label && <label className="input-label">{label || "texto"}</label>}
      <div
        className="input-text"
        style={{ background: color, cursor: "pointer" }}
      >
        {showPicker && (
          <div style={{ zIndex: 2, position: "absolute" }}>
            <Picker
              value={color}
              onChange={(newColor) => {
                onChange(newColor);
                setColor(newColor);
              }}
              disabled={true}
              hidePresets={true}
              hideAdvancedSliders={true}
              hideColorGuide={true}
              hideInputType={true}
              hideOpacity={true}
              className="color-picker__wrapper"
            />
          </div>
        )}
      </div>
    </div>
  );
};

ColorPicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default ColorPicker;
