// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notes-editor-button {
  background: none;
  color: white;
  border: none;
  cursor: pointer;
}

`, "",{"version":3,"sources":["webpack://./src/modules/components/common/Planner/Nodes/NotesNode/NoteEditor/EditorButton.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".notes-editor-button {\r\n  background: none;\r\n  color: white;\r\n  border: none;\r\n  cursor: pointer;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
