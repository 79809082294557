import {
  addErrorMessageGlobal,
  addMessageGlobal,
} from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Template {
  static async render(templatecode, datacode, content) {
    const mycontent = content ? `/${content}` : "";
    const mydatacode = datacode ? `/${datacode}` : "";
    const response = await HttpMethods.request_get(
      `/v1/template/render/${templatecode}${mydatacode}${mycontent}`,
      "text"
    );
    return response;
  }

  static async create(data) {
    const response = await HttpMethods.request_post(
      "/v1/template/create",
      data
    );
    if (response.error) {
      addErrorMessageGlobal(
        `Error al crear el template.\nError: ${response.error}: ${response.message}`
      );
    }
    if (response.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response?.template;
  }

  static async update(data) {
    let url = "/v1/template/update";
    if (data.templatecode) url += `/${data.templatecode}`;
    const response = await HttpMethods.request_post(url, data, true);

    try {
      const result = JSON.parse(response);
      if (result.result) {
        addMessageGlobal(`${result.result}`);
      }
    } catch {
      addErrorMessageGlobal(
        `Error al actualizar el template.\nError: ${response.error}: ${response.message}`
      );
    }

    if (response.error) {
      addErrorMessageGlobal(
        `Error al actualizar el template.\nError: ${response.error}: ${response.message}`
      );
    }
    return response?.template;
  }

  static async delete(templatecode) {
    const response = await HttpMethods.request_delete(
      `/v1/template/delete`,
      templatecode
    );
    if (response?.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response;
  }

  static async list() {
    const response = await HttpMethods.request_get("/v1/template/list");
    if (!response) {
      addErrorMessageGlobal("Error al obtener los templates");
    }
    return response?.templates;
  }

  static async listflat(first = 0, pagination = 100) {
    let response = await HttpMethods.request_get(
      `/v1/template/list?format=flat`
    );
    if (!response) {
      addErrorMessageGlobal("Error al obtener los templates");
      response = [];
    }
    return response;
  }

  static async read(templatecode) {
    const response = await HttpMethods.request_get(
      `/v1/template/read/${templatecode}`
    );
    if (!response) {
      addErrorMessageGlobal("Error al leer el template");
    }
    return response?.template;
  }

  static async test(datacode, data, content, scope) {
    if (!content) content = "empty";
    const response = await HttpMethods.request_post(
      `/v1/template/test/${datacode}/${content}/${scope}`,
      data,
      "text"
    );
    return response;
  }

  static async testAuto(datacode, data, content, scope) {
    if (!content) content = "empty";
    const response = await HttpMethods.request_post(
      `/v1/template/test/${datacode}/${content}/${scope}`,
      "_auto_",
      "text"
    );
    return response;
  }

  static async testAutoTemplate(datacode, data, content, scope) {
    if (!content) content = "empty";
    const response = await HttpMethods.request_post(
      `/v1/template/test/${datacode}/${content}/${scope}`,
      "_autotemplate_",
      "text"
    );
    return response;
  }
}
