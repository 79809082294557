import { SvgIcon } from "@mui/material";
import React from "react";
import {
  Delete as DeleteIcon,
  Help as InfoIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import "./NotificationItem.css";

const NotificationItem = ({
  status,
  message,
  onDelete,
  timeGeneratedAt,
  id,
}) => {
  const renderStatusIcons = (status) => {
    switch (status) {
      case "info":
        return InfoIcon;
      case "error":
        return ErrorIcon;
      default:
        return InfoIcon;
    }
  };
  return (
    <div className="notification-item">
      <div className={`notification-item__icon status--${status}`}>
        {/** [error,info] */}
        <SvgIcon component={renderStatusIcons(status)}></SvgIcon>
      </div>
      <div className="notification-item__body">
        <div className="notification-item__status">{status}</div>
        <div className="notification-item__message">{message}</div>
        <div className="notification-item__time">{timeGeneratedAt}</div>
      </div>
      <div className="notification-item__delete" onClick={() => onDelete(id)}>
        <SvgIcon component={DeleteIcon}></SvgIcon>
      </div>
    </div>
  );
};

NotificationItem.propTypes = {
  status: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  timeGeneratedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  id: PropTypes.string.isRequired,
};

export default NotificationItem;
