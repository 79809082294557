import {
  addErrorMessageGlobal,
  addMessageGlobal,
} from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Check {
  static async reset() {
    const response = await HttpMethods.request_get("/v1/check/reset");
    if (!response) addErrorMessageGlobal("Error al resetear el flow");
    return response;
  }

  static async list(framecode, first = 0, pagination = 10000, props) {
    const response = await HttpMethods.request_get(
      `/v1/check/list/-/${framecode}/${first}/${pagination}${
        props.cartridgecode ? `?cartridgecode=${props.cartridgecode}` : ""
      }`
    );
    if (!response) addErrorMessageGlobal("Error al obtener los checks");
    return response;
  }

  static async listflat(framecode, first = 0, pagination = 10000, props) {
    const response = await HttpMethods.request_get(
      `/v1/check/list/-/${framecode}/${first}/${pagination}?format=flat${
        props.cartridgecode ? `&cartridgecode=${props.cartridgecode}` : ""
      }`
    );
    if (!response) addErrorMessageGlobal("Error al obtener los checks");
    return response;
  }

  static async read(checkCode) {
    const response = await HttpMethods.request_get(
      `/v1/check/read/${checkCode}`
    );
    if (!response) addErrorMessageGlobal("Error al leer el check");
    return response?.check;
  }

  static async create(check) {
    const response = await HttpMethods.request_post("/v1/check/create", check);
    if (response.error) {
      addErrorMessageGlobal(
        `Error al crear el check.\nError: ${response.error}: ${response.message}`
      );
    }
    if (response.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response;
  }

  static async update(check) {
    const response = await HttpMethods.request_post("/v1/check/update", check);
    if (response.error) {
      addErrorMessageGlobal(
        `Error al actualizar el check.\nError: ${response.error}: ${response.message}`
      );
    }
    if (response.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response;
  }

  static async crupdate(check) {
    const response = await HttpMethods.request_post(
      "/v1/check/crupdate",
      check
    );
    if (response.error) {
      addErrorMessageGlobal(
        `Error al crear el check.\nError: ${response.error}: ${response.message}`
      );
    }
    if (response.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response;
  }

  static async delete(checkCode) {
    const response = await HttpMethods.request_delete(
      "/v1/check/delete",
      checkCode
    );
    if (response.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response;
  }

  static async test(scopecode, check) {
    const response = await HttpMethods.request_post(
      `/v1/check/test/${scopecode}`,
      check
    );
    if (!response) addErrorMessageGlobal("Error al testear los checks");
    return response;
  }
}
