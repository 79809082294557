// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--detail-color);
  margin-bottom: 10px;
}
.notification-item:last-child {
  margin-bottom: 0px;
  border-bottom: none;
}
.notification-item__icon {
  margin-right: 10px;
}
.notification-item__icon.status--info {
  color: var(--warning-color);
}

.notification-item__icon.status--error {
  color: var(--error-color);
}
.notification-item__body {
  flex-grow: 1;
}

.notification-item__status {
  font-weight: bold;
  margin-bottom: 5px;
}

.notification-item__message {
  margin-bottom: 5px;
}

.notification-item__time {
  font-size: 0.9em;
}

.notification-item__delete {
  cursor: pointer;
  transition: color 0.2s;
  margin-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/modules/components/header/NavBar/Notifications/NotificationsItem/NotificationItem.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,4CAA4C;EAC5C,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,iBAAiB;AACnB","sourcesContent":[".notification-item {\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 10px;\r\n  border-bottom: 1px solid var(--detail-color);\r\n  margin-bottom: 10px;\r\n}\r\n.notification-item:last-child {\r\n  margin-bottom: 0px;\r\n  border-bottom: none;\r\n}\r\n.notification-item__icon {\r\n  margin-right: 10px;\r\n}\r\n.notification-item__icon.status--info {\r\n  color: var(--warning-color);\r\n}\r\n\r\n.notification-item__icon.status--error {\r\n  color: var(--error-color);\r\n}\r\n.notification-item__body {\r\n  flex-grow: 1;\r\n}\r\n\r\n.notification-item__status {\r\n  font-weight: bold;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.notification-item__message {\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.notification-item__time {\r\n  font-size: 0.9em;\r\n}\r\n\r\n.notification-item__delete {\r\n  cursor: pointer;\r\n  transition: color 0.2s;\r\n  margin-left: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
