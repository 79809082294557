import React from "react";
import Input from "../../../Input/Input";
import Select from "../../../Select/Select";
import DefaultSidebarItems from "../DefaultSidebarItems";

const EventNodeSidebar = ({ data, onChange }) => {
  const eventtypeOptions = [
    { label: "Presencial", value: "onsite" },
    { label: "Videoconferencia", value: "videoconference" },
    { label: "Otros", value: "other" },
  ];

  return (
    <>
      <DefaultSidebarItems data={data} onChange={onChange} />
      <Input
        type="number"
        name="fixdays"
        value={data.fixdays}
        label="Plazo fijo"
        onChange={onChange("fixdays")}
        unit="días"
      />
      <Select
        name="eventtype"
        value={eventtypeOptions.find((opt) => opt.value === data.eventtype)}
        label="Tipo de Evento"
        onChange={onChange("eventtype")}
        options={eventtypeOptions}
      />
      <Input
        type="text"
        name="alerttext"
        value={data.alerttext}
        label="Mensaje de aviso"
        onChange={onChange("alerttext")}
      />
      <Input
        type="number"
        name="alertdays"
        value={data.alertdays}
        label="Días de aviso"
        onChange={onChange("alertdays")}
        unit="días"
      />
    </>
  );
};

export default EventNodeSidebar;
