import React from 'react'
import View from 'src/routes/View/View';
import { ViewContextProvider } from "src/modules/contexts/ViewContextProvider";

const ChecksTab = () => {
  return (
    <div style={{ marginTop: "10px" }}>
    <ViewContextProvider>
      <View vid="dchecks" />
    </ViewContextProvider>
  </div>
  )
}

export default ChecksTab