import React, { useEffect, useState } from 'react';
import './Profile.css';
import { User as UserAPI } from 'src/lib/api';
import Collapsible from 'src/modules/components/common/Collapsible/Collapsible';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const {
    name = '',
    email = '',
    role = '',
    groups = [],
    policies = [],
  } = userData || {};

  useEffect(() => {
    const fetchUser = () => {
      UserAPI.get().then((response) => {
        setUserData(response);
      });
    };
    fetchUser();
  }, []);

  const getInitials = (fullName) => {
    const safeName = fullName?.trim() || '';
    if (!safeName) return '';
    const names = safeName.split(' ');
    return names
      .filter((n) => n.length > 0)
      .map((n) => n[0]?.toUpperCase())
      .slice(0, 2)
      .join('');
  };

  if (!userData) return null;

  return (
    <div className="profile-container">
      <div className="profile-avatar-container">
        <span className="profile-avatar-initials">{getInitials(name)}</span>
      </div>
      <div className="profile-name">{name}</div>
      <div className="profile-role">{role}</div>

      <div className="profile-info-container">
        <div className="profile-label">Email Address:</div>
        <div className="profile-value">{email}</div>

        <div className="profile-label">Rol:</div>
        <div className="profile-value">{role}</div>

        <div className="profile-label">Grupos:</div>
        <div className="profile-tags-container">
          {groups.length > 0 ? (
            groups.map((g, i) => (
              <span key={i} className="profile-tag">
                {g}
              </span>
            ))
          ) : (
            <div className="profile-no-groups">No se han asignado grupos</div>
          )}
        </div>

        <div className="profile-collapsible-fullwidth">
          <Collapsible header="Políticas">
            {Array.isArray(policies)
              ? policies.map((p, i) => (
                  <li key={i}>{p === '*' ? 'Todos los derechos' : p}</li>
                ))
              : (policies === '*' ? 'Todos los derechos' : policies)}
          </Collapsible>
        </div>
      </div>
    </div>
  );
};

export default Profile;
