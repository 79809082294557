import React from "react";
import Input from "../../../Input/Input";
import Select from "../../../Select/Select";
import DefaultSidebarItems from "../DefaultSidebarItems";

const DocumentNodeSidebar = ({ data, onChange }) => {
  const whoOptions = [
    { label: "Sujeto", value: "subject" },
    { label: "Gestor", value: "gestor" },
    { label: "Otros", value: "other" },
  ];

  return (
    <>
      <DefaultSidebarItems data={data} onChange={onChange}/>
      <Input
        type="text"
        name="filetype"
        value={data.filetype}
        label="Tipo de documento"
        onChange={onChange("filetype")}
        placeholder="CN, DNI, CD, Pasaporte..."
      />
      <Input
        type="text"
        name="filename"
        value={data.filename}
        label="Nombre del archivo"
        onChange={onChange("filename")}
        placeholder="CN de {{dossier.file.name}}"
      />

      <Input
        type="number"
        name="requestlimit"
        value={data.requestlimit}
        label="Plazo Solicitud"
        onChange={onChange("requestlimit")}
        placeholder="1"
        unit="días"
      />
      <Input
        type="number"
        name="documentlimit"
        value={data.documentlimit}
        label="Plazo Documento"
        onChange={onChange("documentlimit")}
        unit="días"
      />
      <Select
        name="who"
        value={
          whoOptions.find((opt) => opt.value === data.who) ?? null
        }
        label="Quién lo realiza"
        onChange={onChange("who")}
        options={whoOptions}
      />
      <Input
        type="checkbox"
        name="must"
        value={data.must}
        label="Obligatorio"
        onChange={onChange("must")}
      />
    </>
  );
};

export default DocumentNodeSidebar;
