import React, { forwardRef } from "react";
import PropTypes from "prop-types"; // Importar PropTypes para la validación
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import './EditorButton.css'

const EditorButton = forwardRef(({
  name,
  type = "button",
  icon,
  tooltip,
  onClick,
  id
}, ref) => {
  const newicon = icon?.toLowerCase().replace(/ /g, "_");

  const buttonContent = (
    <button
      ref={ref}
      className="notes-editor-button"
      onClick={onClick}
      name={name}
      type={type}
      id={id}
    >
      {newicon && <Icon>{newicon === "recycle" ? "recycling" : newicon}</Icon>}
    </button>
  );

  return tooltip ? (
    <Tooltip title={tooltip}>
      {buttonContent}
    </Tooltip>
  ) : (
    buttonContent
  );
});

// Definir propTypes para la validación de las props
EditorButton.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string, 
  icon: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default EditorButton