import React from "react";

const ThemeOption = ({ option }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <div
        style={{
          background: option.color,
          borderRadius: "16px",
          border: "1px solid gainsboro",
          width: "20px",
          height: "20px",
        }}
      />
      <span>{option.name}</span>
    </div>
  );
};

export default ThemeOption;
