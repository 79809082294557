import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Update {
  static async updateFront() {
    const response = await HttpMethods.request_get(
      `/v1/global/update/frontend`
    );
    if (response.error) {
      if (response.error === 29000) {
        addErrorMessageGlobal(
          "Para poder actualizar a la nueva versión es necesario actualizar el webhook."
        );
        window.open("https://www.google.es", "_blank");
        throw new Error("WebhookUpdateRequired"); // Lanza una excepción específica
      }
      throw new Error(`Error al actualizar el frontend: ${response.message}`);
    }
  }

  static async updateApi() {
    const response = await HttpMethods.request_get(`/v1/global/update/backend`);
    if (response.error) {
      if (response.error === 29000) {
        addErrorMessageGlobal(
          "Para poder actualizar a la nueva versión es necesario actualizar el webhook."
        );
        window.open("https://www.google.es", "_blank");
        throw new Error("WebhookUpdateRequired"); // Lanza una excepción específica
      }
      throw new Error(`Error al actualizar la API: ${response.message}`);
    }
  }

  static async updateConductor() {
    const response = await HttpMethods.request_get(
      `/v1/global/update/conductor`
    );
    if (response.error) {
      if (response.error === 29000) {
        addErrorMessageGlobal(
          "Para poder actualizar a la nueva versión es necesario actualizar el webhook."
        );
        window.open("https://www.google.es", "_blank");
        throw new Error("WebhookUpdateRequired"); // Lanza una excepción específica
      }
      throw new Error(`Error al actualizar Conductor: ${response.message}`);
    }
  }

  static async updateFileSync() {
    const response = await HttpMethods.request_get(
      `/v1/global/update/filesync`
    );
    if (response.error) {
      if (response.error === 29000) {
        addErrorMessageGlobal(
          "Para poder actualizar a la nueva versión es necesario actualizar el webhook."
        );
        window.open("https://www.google.es", "_blank");
        throw new Error("WebhookUpdateRequired"); // Lanza una excepción específica
      }
      throw new Error(`Error al actualizar FileSync: ${response.message}`);
    }
  }

  static async updateDataBase() {
    const response = await HttpMethods.request_get(
      `/v1/global/update/database`
    );
    if (response.error) {
      if (response.error === 29000) {
        addErrorMessageGlobal(
          "Para poder actualizar a la nueva versión es necesario actualizar el webhook."
        );
        window.open("https://www.google.es", "_blank");
        throw new Error("WebhookUpdateRequired"); // Lanza una excepción específica
      }
      throw new Error(`Error al actualizar Base de datos: ${response.message}`);
    }
  }
}
