import { HttpMethods } from "./HttpMethods.js";
import { API_URL } from "../../config.js";
import {
  addErrorMessageGlobal,
  addMessageGlobal,
} from "src/modules/contexts/MessageContextProvider.js";

export class Global {
  static async import({ importfile, filetype, replace }) {
    const token = HttpMethods.getToken();
    const formData = new FormData();
    formData.append("importfile", importfile);
    formData.append("filetype", filetype);
    formData.append("replace", replace);

    const headers = {
      Authorization: token,
    };

    let result = await fetch(API_URL + "/v1/global/import/", {
      headers,
      method: "POST",
      body: formData,
    });
    return result.json();
  }

  static async export(data) {
    const response = await HttpMethods.request_post("/v1/global/export", data);
    if (response.error) {
      addErrorMessageGlobal(
        `Error al exportar.\nError: ${response.error}: ${response.message}`
      );
    }
    if (response.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response;
  }

  static async upload({ file, target, path }) {
    const token = HttpMethods.getToken();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("target", target);
    formData.append("path", path);

    const headers = {
      Authorization: token,
    };

    let result = await fetch(`${API_URL}/v1/global/upload`, {
      headers,
      method: "POST",
      body: formData,
    });
    return result.json();
  }
}
