import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import TableViewCreate from "./ComponentesViewCreate/TablaViewCreate.jsx";
import { useViewCreateContext } from "./ViewCreateContext.js";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import "./viewCreate.css";
import { View as ViewApi } from "src/lib/api.js";
import FormContainer from "src/modules/components/Containers/FormContainer/FormContainer.jsx";

const ViewCreate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setNavBarTitle } = useAppContext();
  const searchParams = new URLSearchParams(location.search);
  const viewCode = searchParams.get("viewcode");
  const { viewData, updateViewData, resetViewData, editMode, setEditMode } =
    useViewCreateContext();

  // ###############################  LÓGICA EDIT MODE  ################################ //

  // Si hay id en url:
  useEffect(() => {
    if (viewCode) {
      setEditMode(true); // Activa el modo de edición de view si se proporciona un ID
      setNavBarTitle("Editar View");
      getView(viewCode); // Intenta obtener la vista correspondiente
    } else {
      setNavBarTitle("Crear View");
    }
  }, [viewCode]);

  // ----------------------------------- Fetchs ---------------------------------------- //

  // Fetch para traer la view a través de la id en la url
  const getView = async (viewCode) => {
    try {
      let viewDataToEdit = await ViewApi.read(viewCode); // Usa la clase View para obtener los datos
      if (viewDataToEdit) {
        // Procesar cada frame y sus elementos para asegurarse de que cada element tenga un name válido
        if (viewDataToEdit.frames && Array.isArray(viewDataToEdit.frames)) {
          viewDataToEdit.frames.forEach((frame) => {
            if (frame.elements && Array.isArray(frame.elements)) {
              frame.elements.forEach((element) => {
                // Si el element no tiene un name o el name está vacío, asignar el valor de _id a name
                if (!element.name) {
                  element.name = element._id;
                }
              });
            }
          });
        }

        updateViewData(viewDataToEdit); // Actualiza el contexto con los datos obtenidos y modificados
      } else {
        navigate("/view/edit");
        console.log(
          "No se encontraron datos para la vista con viewCode:",
          viewCode
        );
      }
    } catch (error) {
      navigate("/view/edit");
      console.error("Error al obtener la vista:", error);
    }
  };

  // Fetch Para borrar la View de base de datos
  const deleteView = async (viewcode) => {
    // Solicita confirmación del usuario
    const isConfirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar esta vista? Esta acción es irreversible."
    );

    if (isConfirmed) {
      try {
        // Llama al método delete de la clase View
        const response = await ViewApi.delete(viewcode);
        if (response.result) {
          alert(`Resultado: ${response.result}`); // Informa al usuario
        }
      } catch (error) {
        // Maneja posibles errores
        console.error("Error al eliminar la vista:", error);
        alert("No se pudo eliminar la vista. Por favor, intenta de nuevo.");
      }
    } else {
      // El usuario canceló la operación
      console.log("Operación de eliminación cancelada. Error indeterminado");
    }
  };

  // Fetch para actualizar la información de la view en DB
  const updateView = async (newData) => {
    try {
      const response = await ViewApi.update(newData);
      if (response.error) {
        alert(
          "Error al actualizar el view." +
            `\nError: ${response.error}: ${response.message}`
        );
      } else {
        // Si la respuesta incluye el view actualizado, podrías actualizar el contexto aquí,
        // aunque eso requeriría que la función para actualizar el contexto esté disponible.
        alert("View actualizado con éxito");
      }
    } catch (error) {
      console.error("Error al actualizar la vista:", error);
      alert("Vista actualizada con éxito.");
    }
  };

  // ---------------------------------------- Funciones ------------------------------------------ //

  // Función para crear la view en la base de datos
  const createView = () => {
    ViewApi.create(viewData);
    resetViewData();
    navigate(`/view/edit?viewcode=${viewData.viewcode}`);
  };

  // Función para actualizar la View en DB.
  const updateThisView = () => {
    updateView(viewData);
    // resetViewData();
  };

  //Función para borrar la view de base de datos
  const deleteThisView = () => {
    deleteView(viewCode);
    resetViewData();
    navigate("/view/edit");
  };

  // ##################################################################################################### //

  // Controla los inputs de View
  const handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    updateViewData({
      ...viewData,
      [name]: value,
    });
  };

  return (
    <main className="parent-vc">
      {" "}
      <section className="div1-vc">
        {" "}
        <FormContainer>
          <div className="inputs-view-vc">
            <div className="inputs-view-vc-left">
              {" "}
              <Input
                name="name"
                type="text"
                value={viewData.name}
                onChange={handleInputChange}
                label="Nombre"
                placeholder="Nombre"
              />
              <Input
                name="title"
                type="text"
                value={viewData.title}
                onChange={handleInputChange}
                label="Titulo"
                placeholder="Titulo"
              />
              <Input
                name="viewcode"
                type="text"
                value={viewData.viewcode}
                onChange={handleInputChange}
                label="View Code"
                placeholder="Dvistaejemplo"
              />
            </div>
            <div className="inputs-view-vc-right">
              <Input
                name="public"
                type="checkbox"
                value={viewData.public}
                onChange={handleInputChange}
                label="Publico:"
              />
              <Input
                name="system"
                type="checkbox"
                value={viewData.system}
                onChange={handleInputChange}
                label="System:"
              />
              <Input
                name="active"
                type="checkbox"
                value={viewData.active}
                onChange={handleInputChange}
                label="Activo:"
              />
            </div>
          </div>
          <TableViewCreate frames={viewData.frames} />

          <div className="row justify-end mt-1vh gap-1">
            {editMode ? (
              <Button
                icon="save"
                disabled={false}
                onClick={updateThisView}
                style={{ minWidth: "0px" }}
              />
            ) : (
              <Button
                icon="save"
                disabled={false}
                onClick={createView}
                style={{ minWidth: "0px" }}
              />
            )}
            {editMode ? (
              <Button
                icon="delete"
                disabled={false}
                onClick={deleteThisView}
                style={{ minWidth: "0px" }}
              />
            ) : null}{" "}
          </div>
        </FormContainer>{" "}
      </section>
      {}
    </main>
  );
};

export default ViewCreate;
