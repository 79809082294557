import React from "react";
import { useSearchParams } from "react-router-dom";
import "./Intendente.css";
import TextArea from "src/modules/components/common/Text Area/TextArea";

const Intendente = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  return (
    <div className="intendente-container">
      {/* Título */}
      <div className="intendente-title">
        Intendente con ID: {id ? id : "No se ha proporcionado un id"}
      </div>

      {/* Contenedor que agrupa editor y sidebar */}
      <div className="intendente-content">
        {/* Editor/Chat (columna izquierda) */}
        <div className="intendente-editor">
          <TextArea styleTextArea={{ height: "100%" }}></TextArea>
        </div>

        {/* Barra lateral (columna derecha) */}
        <div className="intendente-sidebar">
          <div className="intendente-sidebar__urls">
            <div className="intendente-sidebar__urls-title">Webs</div>
            <div className="intendente-sidebar__urls-content">
              <TextArea styleTextArea={{ height: "100%" }}></TextArea>
            </div>
          </div>

          <div className="intendente-sidebar__files">
            <div className="intendente-sidebar__files-title">Files</div>
            <div className="intendente-sidebar__files-content">
              <TextArea styleTextArea={{ height: "100%" }}></TextArea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intendente;
