import React, { useEffect, useState, useCallback, useMemo } from "react";
import "../Agentes/Agentes.css";
import "./Roles.css";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import Select from "src/modules/components/common/Select/Select";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import {
  Auth as AuthAPI,
  Assistant as AssistantAPI,
  User as UserAPI,
} from "src/lib/api";
import { Icon } from "@mui/material";
import { defaultRoles } from "./defaultRoles/defaultRoles";
import Policies from "src/modules/components/common/Policies/Policies";

// Custom Hook for fetching data
const useFetchData = (setAssistentes, setPolicies) => {
  useEffect(() => {
    const fetchPolicies = async () => {
      const policies = await AuthAPI.getPolicies();
      setPolicies(policies);
    };

    const fetchAssistants = async () => {
      const res = await AssistantAPI.list();
      setAssistentes(res.assistants.map(({ id, name }) => ({ id, name })));
    };

    fetchPolicies();
    fetchAssistants();
  }, [setAssistentes, setPolicies]);
};

// Custom Hook for setting roles in context
const useSetupInfoUpdate = (roles, setSetupInfo) => {
  useEffect(() => {
    setSetupInfo((prev) => ({ ...prev, roles }));
  }, [roles, setSetupInfo]);
};

const Roles = () => {
  const { setupData: setupInfo, setSetupData: setSetupInfo } = useAppContext();
  const [assistentes, setAssistentes] = useState([]);
  const [roles, setRoles] = useState(setupInfo?.roles || []);
  const [policies, setPolicies] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [openBox, setOpenBox] = useState(false);
  const [editedRole, setEditedRole] = useState(null);
  const [newRole, setNewRole] = useState(false);

  const listUsersOptions = [
    { label: "Solo tú", value: "onlyyou" },
    { label: "Todos", value: "all" },
    { label: "Mismo rol", value: "samerole" },
    { label: "Mismo grupo", value: "samegroup" },
  ];

  useFetchData(setAssistentes, setPolicies);
  useSetupInfoUpdate(roles, setSetupInfo);
  const handleRoleClick = useCallback(
    (index) => {
      setSelectedRole(roles[index]);
      setEditedRole({ ...roles[index] });
      setOpenBox(true);
    },
    [roles]
  );

  const handleEditRole = useCallback((e) => {
    const { name, value } = e.target;
    setEditedRole((prev) => ({ ...prev, [name]: value }));
  }, []);

  // const handlePolicyChange = useCallback((policy) => {
  //   setEditedRole((prev) => {
  //     const policies = prev.policies.includes(policy)
  //       ? prev.policies.filter((p) => p !== policy)
  //       : [...prev.policies, policy];
  //     return { ...prev, policies };
  //   });
  // }, []);

  const handleSetPolicies = (ev) => {
    setEditedRole((prev) => {
      return {
        ...prev,
        policies: ev,
      };
    });
  };

  const agregarElemento = useCallback(() => {
    const defaultPolicies = policies
      .filter((policy) => policy.default)
      .map((policy) => policy.policy);
    const newItem = {
      id: crypto.randomUUID(),
      name: "",
      description: "",
      assistants: [],
      policies: defaultPolicies,
    };
    setEditedRole(newItem);
    setSelectedRole(newItem);
    setNewRole(true);
    setOpenBox(true);
  }, [policies]);

  const handleSaveRole = useCallback(() => {
    if (newRole) {
      setRoles((prev) => [...prev, editedRole]);
    } else {
      setRoles((prev) =>
        prev.map((role) => (role === selectedRole ? editedRole : role))
      );
    }
    setOpenBox(false);
    resetEditState();
  }, [newRole, editedRole, selectedRole]);

  const handleDeleteRole = useCallback(() => {
    setRoles((prev) => prev.filter((role) => role !== selectedRole));
    setOpenBox(false);
    resetEditState();
  }, [selectedRole]);

  const resetEditState = useCallback(() => {
    setSelectedRole(null);
    setEditedRole(null);
    setNewRole(false);
  }, []);

  const handleAssistantsChange = useCallback((selectedOptions) => {
    const assistants = selectedOptions
      ? selectedOptions.map((option) => option.id)
      : [];
    setEditedRole((prev) => ({ ...prev, assistants }));
  }, []);

  const handleListUsersChange = useCallback((selectedOption) => {
    setEditedRole((prev) => ({ ...prev, listusers: selectedOption.value }));
  }, []);

  const onClickExit = useCallback(() => {
    setOpenBox(false);
    resetEditState();
  }, [resetEditState]);

  const footerBoxFloat = useMemo(
    () => (
      <div className="agente-container-buttons">
        <Button icon="save" onClick={handleSaveRole} />
        {!newRole && <Button icon="delete" onClick={handleDeleteRole} />}
      </div>
    ),
    [handleSaveRole, handleDeleteRole, newRole]
  );

  const updateMemberPolicies = async (role) => {
    function fetchData() {
      return UserAPI.list();
    }
    function updateUser(user) {
      return UserAPI.update(user);
    }
    const users = await fetchData();
    const userWithRole = users.filter((user) => user.role === role.name);
    for (let user of userWithRole) {
      user.policies = role.policies;
      await updateUser(user);
    }
    alert(
      "Se han actualizado las politicas de los usuarios con el rol " + role.name
    );
  };

  const createDefaultRoles = () => {
    const defaultRolesArray = defaultRoles();
    setRoles((prev) => {
      const newRoles = [...prev];
      for (const defaultRole of defaultRolesArray) {
        const foundRole = newRoles.find((el) => el.name === defaultRole.name);
        if (!foundRole) newRoles.push(defaultRole);
      }
      return newRoles;
    });
  };
  return (
    <div className="agentes-container">
      <div className="roles-container-buttons">
        <Button
          content={"Crear roles por defecto"}
          onClick={createDefaultRoles}
        />
      </div>
      {openBox && (selectedRole || newRole) && (
        <BoxFloat
          title={selectedRole ? "Editar Rol" : "Agregar Rol"}
          onClickExit={onClickExit}
          onClose={selectedRole ? onClickExit : undefined}
          footer={footerBoxFloat}
        >
          <Input
            name="name"
            type="text"
            onChange={handleEditRole}
            value={editedRole?.name || ""}
            label="Nombre"
          />
          <Input
            name="description"
            type="text"
            onChange={handleEditRole}
            value={editedRole?.description || ""}
            label="Descripción"
          />
          <Select
            name="assistants"
            isMulti
            options={assistentes}
            onChange={handleAssistantsChange}
            label="Asistentes"
            value={assistentes.filter(({ id }) =>
              editedRole?.assistants.includes(id)
            )}
          />
          <Select
            name="listusers"
            options={listUsersOptions}
            onChange={handleListUsersChange}
            label="Listar usuarios"
            value={listUsersOptions.find(
              (option) => option.value === editedRole?.listusers
            )}
          />
          <Policies
            policies={selectedRole.policies}
            setPolicies={(event) => handleSetPolicies(event)}
            opened={true}
          />
        </BoxFloat>
      )}
      <div className="display-agentes">
        {roles.map((role, index) => (
          <div
            key={index}
            className="agente-item"
            onClick={() => handleRoleClick(index)}
          >
            <div className="agente-item-title">{role.name}</div>
            <div className="agente-item-description">{role.description}</div>
            <div
              className="agente-item-chatIcon"
              title="Regenerar roles de los usuarios"
              onClick={(e) => {
                e.stopPropagation();
                updateMemberPolicies(role);
              }}
            >
              <Icon>replayicon</Icon>
            </div>
          </div>
        ))}
        <div
          className="agente-item agente-item-add-last"
          onClick={agregarElemento}
        >
          <p>Agregar más</p>
        </div>
      </div>
    </div>
  );
};

export default Roles;
