import { useEffect, useState } from "react";

export const useMenuGenerator = (publicSetup) => {
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (!publicSetup) return;

    const groupedRoutes = [];
    publicSetup.menu.forEach((route) => {
      const pathSegments = route.pathmenu.split("/").filter(Boolean);
      let currentGroup = groupedRoutes;

      pathSegments.forEach((_, idx) => {
        const currentPath = "/" + pathSegments.slice(0, idx + 1).join("/");
        let existingGroup = currentGroup.find(
          (item) => item.pathmenu === currentPath
        );

        if (!existingGroup) {
          existingGroup = {
            id: route.id,
            title: route.title,
            pathmenu: currentPath,
            roles: route.roles || [],
            url: route.url || "",
            active: route.active,
            outwindow: route.outwindow || false,
            submenus: [],
          };
          currentGroup.push(existingGroup);
        }

        currentGroup = existingGroup.submenus;
      });
    });

    const userMenuIndex = groupedRoutes.findIndex(
      (route) => route.pathmenu === "/usermenu"
    );
    if (userMenuIndex !== -1) {
      const userMenu = groupedRoutes.splice(userMenuIndex, 1)[0];
      groupedRoutes.push(userMenu);
    }

    setMenu(groupedRoutes);
  }, [publicSetup]);

  return menu;
};
