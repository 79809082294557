import React, { useEffect } from "react";
// import Notifications from './Notifications/Notifications';
import TimeContainer from "./TimeContainer/TimeContainer";
// import Calendar from './Calendar/Calendar';
import Absences from "./Absences/Absences";
import ClockIns from "./ClockIns/ClockIns";
import Vacations from "./Vacations/Vacations";
import "./UserActivity.css";
import useMessages from "src/modules/contexts/MessageContextProvider";
// Importar datos de prueba desde data.js
import { /**notifications,*/ absences, vacations } from "./testData.js";
import Analysis from "./Analysis/Analysis";
import Activity from "./Activity/Activity";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
function UserActivity() {
  const { setNavBarTitle } = useAppContext();
  useEffect(() => {
    setNavBarTitle("Tu actividad");
  }, []);

  const { addErrorMessage } = useMessages();

  const handleRequestAbsence = () => {
    addErrorMessage("Solicitar ausencia");
  };

  const handleRequestClockIn = () => {
    addErrorMessage("Solicitar fichaje");
  };

  const handleRequestVacation = () => {
    addErrorMessage("Solicitar vacaciones");
  };

  return (
    <div className="user-activity-dashboard">
      {/* Notificaciones */}
      {/* <Notifications notifications={notifications} /> */}
      <Analysis />

      {/* Reloj y Estado de la Jornada */}
      <TimeContainer />

      {/* Calendario */}
      {/* <Calendar title="Calendario">
                <div className="calendar__grid">
                    {['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'].map((day, index) => (
                        <div key={index}>{day}</div>
                    ))}
                </div>
            </Calendar> */}
      <Activity />

      {/* Ausencias */}
      <Absences absences={absences} onRequest={handleRequestAbsence} />

      {/* Fichajes */}
      <ClockIns onRequest={handleRequestClockIn} />

      {/* Vacaciones */}
      <Vacations vacations={vacations} onRequest={handleRequestVacation} />
    </div>
  );
}

export default UserActivity;
