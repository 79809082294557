import React from "react";
import PropTypes from "prop-types";
import "./Message.css";

function Message({ children, type }) {
  let className = "message";
  if (type === "error") {
    className += " message--error";
  } else {
    className += " message--info";
  }

  return <div className={className}>{children}</div>;
}

Message.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["info", "error"]),
};

Message.defaultProps = {
  type: "info",
};

export default Message;
