import { API_URL } from "../../config.js";
import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider.js";

export class HttpMethods {
  /**
   * Método para realizar peticiones GET.
   *
   * @param {string} requestPath - Ruta del endpoint (sin la base URL).
   * @param {("json"|"text")} mode - Modo de parseo de la respuesta.
   * @returns {Promise<any>} - Respuesta del servidor parseada según `mode`.
   */
  static async request_get(requestPath, mode = "json") {
    const options = { method: "GET" };
    return this.sendRequest(requestPath, options, mode);
  }

  /**
   * Método para realizar peticiones POST.
   *
   * @param {string} requestPath - Ruta del endpoint (sin la base URL).
   * @param {any} data - Data a enviar en el cuerpo de la petición.
   * @param {("json"|"text")} mode - Modo de parseo de la respuesta.
   * @returns {Promise<any>} - Respuesta del servidor parseada según `mode`.
   */
  static async request_post(requestPath, data, mode = "json") {
    const isJSON = mode !== "text";
    const options = {
      method: "POST",
      body: isJSON ? JSON.stringify(data) : data,
    };
    return this.sendRequest(requestPath, options, mode);
  }

  /**
   * Método para realizar peticiones DELETE.
   *
   * @param {string} requestPath - Ruta del endpoint (sin la base URL).
   * @param {string|number} itemToDelete - Identificador del elemento a eliminar.
   * @returns {Promise<any>} - Respuesta del servidor, usualmente sin cuerpo.
   */
  static async request_delete(requestPath, itemToDelete) {
    const options = { method: "DELETE" };
    return this.sendRequest(`${requestPath}/${itemToDelete}`, options);
  }

  /**
   * Método genérico para enviar peticiones al servidor.
   *
   * @param {string} requestPath - Ruta del endpoint (sin la base URL).
   * @param {object} options - Opciones para la petición (method, body, etc.).
   * @param {("json"|"text")} mode - Modo de parseo de la respuesta.
   * @returns {Promise<any>} - Respuesta parseada en función de `mode`.
   */
  static async sendRequest(requestPath, options, mode = "json") {
    const token = this.getToken();
    const headers = this._buildHeaders(options.method, mode, token);

    try {
      const response = await fetch(`${API_URL}${requestPath}`, {
        method: options.method,
        headers,
        body: options.body,
      });

      // Manejar estados comunes de forma más concisa
      if ([200, 400, 409].includes(response.status)) {
        return mode === "json" ? await response.json() : await response.text();
      } else {
        // Si no es uno de esos estados, manejamos el error
        return await this.handleResponseError(response, requestPath, options);
      }
    } catch (error) {
      console.error(
        `Error al hacer la petición al servidor ${API_URL}${requestPath}`
      );
      addErrorMessageGlobal(
        `Error al hacer la petición al servidor ${API_URL}${requestPath}\nMessage: ${error.message}`
      );
    }
  }

  /**
   * Maneja los diferentes escenarios de error basados en el estado de la respuesta.
   *
   * @param {Response} response - Objeto de respuesta de la petición.
   * @param {string} requestPath - Ruta del endpoint.
   * @param {object} options - Opciones usadas en la petición (method, body, etc.).
   */
  static async handleResponseError(response, requestPath, options) {
    // Se intenta parsear la respuesta, en caso de contener JSON adicional
    try {
      const responseParsed = await response.json();
      addErrorMessageGlobal(
        `Error: ${responseParsed.error} - Message: ${responseParsed.message}`
      );
      return responseParsed;
    } catch (err) {
      console.error("Error parseando la respuesta:", err);
    }

    if (response.error) {
      // Si el servidor retorna un objeto con { error, message } directamente
      addErrorMessageGlobal(`Error: ${response.error} - ${response.message}`);
      return;
    }

    const status = response.status;
    const method = options.method;
    const item = requestPath.split("/").pop();

    // Manejo de estados específicos
    if (status === 404) {
      if (method === "DELETE") {
        console.error(
          `Error al eliminar el elemento ${item} no encontrado, status: ${status}`
        );
        addErrorMessageGlobal(
          `Error al eliminar el elemento ${item} no encontrado, status: ${status}`
        );
      } else if (method === "GET") {
        console.error(
          `Error al leer el elemento ${item} no encontrado, status: ${status}`
        );
        addErrorMessageGlobal(
          `Error al leer el elemento ${item} no encontrado, status: ${status}`
        );
      }
    } else if (status === 401) {
      console.error(
        `Error en la autenticación con el servidor, status ${status}`
      );
      addErrorMessageGlobal(
        `Error en la autenticación con el servidor, status ${status}`
      );
    } else if (status === 403) {
      console.error(`Error en la validación con el servidor, status ${status}`);
      addErrorMessageGlobal(
        `Error en la validación con el servidor\nstatus ${status}`
      );
    } else {
      console.error(
        `Error al hacer la petición al servidor ${API_URL}${requestPath}, status: ${status}`
      );
      addErrorMessageGlobal(
        `Error al hacer la petición al servidor ${API_URL}${requestPath}\nstatus: ${status}\nMessage: ${response.statusText}`
      );
    }
  }

  /**
   * Obtiene el token guardado en la cookie "user_session" y devuelve
   * un string con formato "Bearer <token>" si existe.
   *
   * @returns {string|null}
   */
  static getToken() {
    const nameEQ = "user_session=";
    const ca = document.cookie.split(";");

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(nameEQ) === 0) {
        try {
          const userSession = JSON.parse(c.substring(nameEQ.length));
          return userSession.token ? `Bearer ${userSession.token}` : null;
        } catch (error) {
          console.error("Error parseando la cookie user_session:", error);
          return null;
        }
      }
    }
    return null;
  }

  /**
   * Construye el objeto de cabeceras para la petición en base a los parámetros.
   * Este método se marca como privado (por convención) para indicar que
   * solo se debe usar internamente en esta clase.
   *
   * @param {string} method - Método HTTP de la petición (GET, POST, DELETE, etc.).
   * @param {("json"|"text")} mode - Modo de parseo de la respuesta.
   * @param {string|null} token - Token para autorización, si existe.
   * @returns {object} - Objeto con las cabeceras.
   */
  static _buildHeaders(method, mode, token) {
    const headers = {};

    if (token) {
      headers["Authorization"] = token;
    }
    if (method === "POST" && mode === "json") {
      headers["Content-Type"] = "application/json";
    }

    return headers;
  }
}
