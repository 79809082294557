import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Table from "src/modules/components/common/Table/Table";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import FormGenerator from "src/modules/components/FormGenerator/FormGenerator";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import { Setup as SetupAPI } from "src/lib/api";
import Button from "src/modules/components/common/Button/Button";
const varAddRole = "addRole";
const varEditRole = "editRole";
const rolDefaultValues = {
  name: "",
  range: "",
  read: true,
  create: false,
  update: false,
  delete: false,
  modifyname: false,
  modifydescription: false,
  modifyowner: false,
  modifyassignedto: false,
  modifygroups: false,
  id: crypto.randomUUID(),
};
const rangeOptions = [
  { value: "all", label: "all" },
  { value: "groups", label: "groups" },
  { value: "assigned", label: "assigned" },
  { value: "owner", label: "owner" },
  { value: "none", label: "none" },
];

const StructureMenuRoles = ({ editedItems, dispatch }) => {
  const { setupData, setSetupData } = useAppContext();
  const [rolesSetupOptions, setRolesSetupOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [rolesFileTypes, setRolesFiletypes] = useState([]);
  const [roleEditingValues, setRoleEditingValues] = useState(null); //{}

  useEffect(() => {
    const result = editedItems.filetype.header.roles.map((el) => {
      return { ...el, id: crypto.randomUUID() };
    });
    setRolesFiletypes(result);
  }, [editedItems.filetype.header.roles]);

  useEffect(() => {
    if (!setupData.roles) {
      SetupAPI.read().then((res) => {
        setSetupData(res);
      });
    }
  }, []);
  useEffect(() => {
    setRolesSetupOptions(() => {
      let options =
        setupData.roles?.map((rol) => {
          return { label: rol.name, value: rol.name };
        }) || [];

      const specialRoles = ["public", "robot"];
      specialRoles.forEach((special) => {
        if (!options.some((opt) => opt.value === special)) {
          options.push({ label: special, value: special });
        }
      });

      return options;
    });
  }, [setupData]);

  const updateHeaderFileType = (key, value) => {
    const newHeader = {
      ...editedItems.filetype.header,
      [key]: value,
    };
    dispatch({
      type: "UPDATE_HEADER",
      payload: newHeader,
    });
  };
  const onSubmitNewRole = (e) => {
    if (!e["name"] || e["range"].length === 0) {
      alert("Complete todos los campos");
      return;
    }
    const newRoles = [...rolesFileTypes, e];
    console.log(e);
    updateHeaderFileType("roles", newRoles);
    setShowModal(false);
  };

  const addAllRolesToTable = () => {
    const rolesAtSetup = setupData.roles;
    const rolesAtTable = rolesFileTypes;

    // Creamos una copia para trabajar
    let newRoles = [...rolesFileTypes];

    // Agregar el rol "robot" si no existe
    const robotAlreadyExists = rolesAtTable.some((el) => el.name === "robot");
    if (!robotAlreadyExists) {
      newRoles.push({
        name: "robot",
        range: "all",
        read: true,
        create: true,
        update: true,
        delete: true,
        modifyname: true,
        modifydescription: true,
        modifyowner: true,
        modifyassignedto: true,
        modifygroups: true,
        id: crypto.randomUUID(),
      });
    }

    // Agregamos todos los roles que no estén ya en la tabla
    const rolesNotInTable = rolesAtSetup.filter(
      (rol) => !rolesAtTable.some((el) => el.name === rol.name)
    );

    const rolesToAdd = rolesNotInTable.map((rol) => {
      return {
        name: rol.name,
        range: "none",
        read: false,
        create: false,
        update: false,
        delete: false,
        modifyname: false,
        modifydescription: false,
        modifyowner: false,
        modifyassignedto: false,
        modifygroups: false,
        id: crypto.randomUUID(),
      };
    });

    newRoles = [...newRoles, ...rolesToAdd];

    updateHeaderFileType("roles", newRoles);
  };

  const deleteRow = (e) => {
    const idElement = e.id;
    const newRolesFileTypes = rolesFileTypes.filter((el) => {
      return el.id !== idElement;
    });
    updateHeaderFileType("roles", newRolesFileTypes);
  };

  const handleCheckboxChange = (id, key) => {
    console.log(id, key);
    const updatedRoles = rolesFileTypes.map((role) => {
      if (role.id === id) {
        return { ...role, [key]: !role[key] };
      }
      return role;
    });
    setRolesFiletypes(updatedRoles);
    updateHeaderFileType("roles", updatedRoles);
  };

  const handleRangeChange = (id, value) => {
    const updatedRoles = rolesFileTypes.map((role) => {
      if (role.id === id) {
        return { ...role, range: value };
      }
      return role;
    });
    setRolesFiletypes(updatedRoles);
    updateHeaderFileType("roles", updatedRoles);
  };

  const columns = [
    {
      label: "Nombre",
      key: "name",
      type: "text",
    },
    {
      label: "Rango",
      key: "range",
      type: "select",
      render: (value, rowData) => (
        <select
          value={value}
          onChange={(e) => handleRangeChange(rowData.id, e.target.value)}
          className="large-select"
        >
          {rangeOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ),
    },
    {
      label: "Read",
      key: "read",
      type: "checkbox",
      editable: true,
      onChange: (id) => handleCheckboxChange(id, "read"),
    },
    {
      label: "Update",
      key: "update",
      type: "checkbox",
      editable: true,
      onChange: (id) => handleCheckboxChange(id, "update"),
    },
    {
      label: "Create",
      key: "create",
      type: "checkbox",
      editable: true,
      onChange: (id) => handleCheckboxChange(id, "create"),
    },
    {
      label: "Delete",
      key: "delete",
      type: "checkbox",
      editable: true,
      onChange: (id) => handleCheckboxChange(id, "delete"),
    },
    {
      label: "Modify Name",
      key: "modifyname",
      type: "checkbox",
      editable: true,
      onChange: (id) => handleCheckboxChange(id, "modifyname"),
    },
    {
      label: "Modify Description",
      key: "modifydescription",
      type: "checkbox",
      editable: true,
      onChange: (id) => handleCheckboxChange(id, "modifydescription"),
    },
    {
      label: "Modify Owner",
      key: "modifyowner",
      type: "checkbox",
      editable: true,
      onChange: (id) => handleCheckboxChange(id, "modifyowner"),
    },
    {
      label: "Modify Assigned To",
      key: "modifyassignedto",
      type: "checkbox",
      editable: true,
      onChange: (id) => handleCheckboxChange(id, "modifyassignedto"),
    },
    {
      label: "Modify Groups",
      key: "modifygroups",
      type: "checkbox",
      editable: true,
      onChange: (id) => handleCheckboxChange(id, "modifygroups"),
    },
    {
      label: "Acciones",
      key: "table_actions",
      type: "actions",
      buttons: [
        {
          icon: "DeleteIcon",
          onClick: (e) => {
            deleteRow(e);
          },
        },
      ],
    },
  ];

  const elements = [
    {
      name: "name",
      component: "select",
      type: "text",
      label: "Nombre",
      key: "name",
      placeholder: "",
      options: rolesSetupOptions?.filter((role) => {
        if (showModal === varEditRole && roleEditingValues) {
          return (
            role.value === roleEditingValues.name ||
            !rolesFileTypes.some(
              (fileTypeRole) => fileTypeRole.name === role.value
            )
          );
        }

        return !rolesFileTypes.some(
          (fileTypeRole) => fileTypeRole.name === role.value
        );
      }),
    },
    {
      name: "range",
      component: "select",
      type: "text",
      label: "Rango",
      key: "range",
      placeholder: "",
      options: rangeOptions,
    },
    {
      name: "read",
      component: "input",
      type: "checkbox",
      label: "Read",
      key: "read",
      checked: false,
    },
    {
      name: "update",
      component: "input",
      type: "checkbox",
      key: "Update",
      label: "update",
      checked: false,
    },
    {
      name: "create",
      component: "input",
      type: "checkbox",
      label: "Create",
      key: "create",
      checked: false,
    },
    {
      name: "delete",
      component: "input",
      type: "checkbox",
      label: "Delete",
      key: "delete",
      checked: false,
    },
    {
      name: "modifyname",
      component: "input",
      type: "checkbox",
      label: "Modify Name",
      key: "modifyname",
      checked: false,
    },
    {
      name: "modifydescription",
      component: "input",
      type: "checkbox",
      label: "Modify Description",
      key: "modifydescription",
      checked: false,
    },
    {
      name: "modifyowner",
      component: "input",
      type: "checkbox",
      label: "Modify Owner",
      key: "modifyowner",
      checked: false,
    },
    {
      name: "modifyassignedto",
      component: "input",
      type: "checkbox",
      label: "Modify Assigned To",
      key: "modifyassignedto",
      checked: false,
    },
    {
      name: "modifygroups",
      component: "input",
      type: "checkbox",
      label: "Modify Groups",
      key: "modifygroups",
      checked: false,
    },
  ];

  return (
    <div className="dossier-dashboard__section-container">
      <p>Indica que roles pueden acceder al filetype y con que permisos</p>
      <div className="structure-body__value">
        <div
          className="structure-body-buttons__value"
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "flex-end",
          }}
        >
          <Button
            content="Agregar todos los roles"
            onClick={() => addAllRolesToTable()}
            tooltip="Agregar todos los roles"
          />

          <Button
            content=""
            onClick={() => setShowModal(varAddRole)}
            tooltip="Añadir nuevo rol"
            icon={"AddCircleOutline"}
          />
        </div>
        {showModal === varAddRole && (
          <BoxFloat
            title="Agregar nuevo rol"
            onClickExit={() => setShowModal(null)}
            onClose={() => setShowModal(null)}
          >
            <FormGenerator
              form={{ elements: elements }}
              onSubmit={onSubmitNewRole}
              initialValues={rolDefaultValues}
            />
          </BoxFloat>
        )}
        {showModal === varEditRole && (
          <BoxFloat
            title="Editando rol"
            onClickExit={() => setShowModal(null)}
            onClose={() => setShowModal(null)}
          >
            <FormGenerator
              form={{ elements: elements }}
              onSubmit={onSubmitEditRole}
              initialValues={roleEditingValues}
            />
          </BoxFloat>
        )}
        <Table columns={columns} data={rolesFileTypes} />
      </div>
    </div>
  );
};

// Validación de props
StructureMenuRoles.propTypes = {
  editedItems: PropTypes.shape({
    filetype: PropTypes.shape({
      header: PropTypes.shape({
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            range: PropTypes.arrayOf(PropTypes.string).isRequired,
            filter: PropTypes.arrayOf(PropTypes.string).isRequired,
          })
        ),
      }),
      structure: PropTypes.array,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default StructureMenuRoles;
