import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { useAppContext } from 'src/modules/contexts/AppContextProvider';

const AspectoContext = createContext();

const defaultColors = {
    "--demo-primary-color": '#000000',
    "--demo-bg-color": '#FFFFFF',
    "--demo-detail-color": '#CCCCCC',
    "--demo-error-color": '#000000',
    "--demo-warning-color": '#FF0000',
    "--demo-font-color": '#FFA500',
    "--demo-font-family": 'Ping LCG',
};

const colorMapping = {
    primarycolor: "--demo-primary-color",
    backcolor: "--demo-bg-color",
    detailcolor: "--demo-detail-color",
    fontcolor: "--demo-font-color",
    errorcolor: "--demo-error-color",
    warningcolor: "--demo-warning-color",
    fonttype: "--demo-font-family",
};

function invertObject(obj) {
    const inverted = {};

    for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            inverted[obj[key]] = key;
        }
    }

    return inverted;
}
export const AspectoContextProvider = ({ children }) => {
    const { setupData, setSetupData, /**publicSetup*/ } = useAppContext();
    const [aspectoDemoVariables, setAspectoDemoVariables] = useState(() => {
        //sustituye los valores por defecto que encuentra en setup
        const initialState = { ...defaultColors }
        const colorKeys = Object.keys(colorMapping)
        for (const key in setupData?.app) {
            if (colorKeys.includes(key)) {
                initialState[colorMapping[key]] = setupData.app[key]
            }
        }
        // for (const key in publicSetup) {
        //     if (colorKeys.includes(key)) {
        //         console.log(colorMapping[key], key)
        //         initialState[colorMapping[key]] = publicSetup[key]
        //     }
        // }
        return initialState
    });

    useEffect(() => {
        if (aspectoDemoVariables) {
            document.documentElement.style.setProperty("--demo-primary-color", aspectoDemoVariables["--demo-primary-color"]);
            document.documentElement.style.setProperty("--demo-bg-color", aspectoDemoVariables["--demo-bg-color"]);
            document.documentElement.style.setProperty("--demo-detail-color", aspectoDemoVariables["--demo-detail-color"]);
            document.documentElement.style.setProperty("--demo-error-color", aspectoDemoVariables["--demo-error-color"]);
            document.documentElement.style.setProperty("--demo-warning-color", aspectoDemoVariables["--demo-warning-color"]);
            document.documentElement.style.setProperty("--demo-font-color", aspectoDemoVariables["--demo-font-color"]);
            document.documentElement.style.setProperty("--demo-font-family", aspectoDemoVariables["--demo-font-family"]);
        }

        setSetupData((prevValues) => {
            let normalizedColors = {}
            let valueColors = invertObject(colorMapping)
            for (let key in aspectoDemoVariables) {
                normalizedColors[valueColors[key]] = aspectoDemoVariables[key]
            }
            return {
                ...prevValues,
                app: {
                    ...prevValues.app,
                    ...normalizedColors
                },
            };
        });

    }, [aspectoDemoVariables, setSetupData]);


    return (
        <AspectoContext.Provider value={{ aspectoDemoVariables, setAspectoDemoVariables }}>
            {children}
        </AspectoContext.Provider>
    );
};

// Hook para usar el contexto
export const useAspectoContext = () => {
    return useContext(AspectoContext);
};

// Validación de PropTypes
AspectoContextProvider.propTypes = {
    children: PropTypes.node
};
