import {
  addErrorMessageGlobal,
  addMessageGlobal,
} from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Structure {
  static async list() {
    const response = await HttpMethods.request_get("/v1/filetype/list");
    if (!response) {
      addErrorMessageGlobal("Error al obtener los structures");
    }
    return response?.structures;
  }

  static async read(filetypeName) {
    const response = await HttpMethods.request_get(
      `/v1/filetype/read/${filetypeName}`
    );
    if (!response) {
      addErrorMessageGlobal("Error al obtener el structure");
    }
    return response;
  }

  static async remotes() {
    const response = await HttpMethods.request_get(`/v1/archive/remotes`);
    if (!response) {
      addErrorMessageGlobal("Error al obtener la lista de remotos");
    }
    return response;
  }

  static async create(structure) {
    const response = await HttpMethods.request_post(
      "/v1/filetype/create",
      structure
    );
    if (response.error) {
      addErrorMessageGlobal(
        `Error al crear el structure.\nError: ${response.error}: ${response.message}`
      );
    }
    if (response.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response;
  }

  static async crupdate(structure) {
    const response = await HttpMethods.request_post(
      "/v1/filetype/crupdate",
      structure
    );
    if (response.error) {
      addErrorMessageGlobal(
        `Error al crear el structure.\nError: ${response.error}: ${response.message}`
      );
    }
    if (response.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response;
  }

  static async delete(filetypeName) {
    const response = await HttpMethods.request_delete(
      `/v1/filetype/delete`,
      filetypeName
    );
    if (response?.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response;
  }

  static async structure(filetypename) {
    const response = await HttpMethods.request_get(
      `/v1/filetype/structure/${filetypename}`
    );
    if (!response) {
      addErrorMessageGlobal(`Error al obtener el filetype ${filetypename}`);
    }
    return response;
  }
}
