import constants from "src/definitions/constants";

export const getId = () => {
  const caracteres =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const longitud = 20; // Puedes ajustar la longitud según tus necesidades
  let resultado = "";

  for (let i = 0; i < longitud; i++) {
    const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
    resultado += caracteres.charAt(indiceAleatorio);
  }

  return resultado;
};

export function extractLabelsAndValues(data) {
  let result = [];

  function recurse(items) {
    items.forEach((item) => {
      result.push({
        label: `${item.title} | dossier.${item.fullvarname}`,
        value: `dossier.${item.fullvarname}`,
        type: item.datatype,
      });

      // Si existe una propiedad 'structure' y es un array, continúa recursivamente
      if (Array.isArray(item.structure)) {
        recurse(item.structure);
      }
    });
  }

  recurse(data);
  return result;
}

export const isEqual = (objA, objB) => {
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (let key of keysA) {
    if (objA[key] !== objB[key]) {
      return false;
    }
  }

  return true;
};

const searchNodesPosition = (nodes) => {
  const nodesX0 = nodes.filter((nodo) => nodo.position.x === 0);

  // Ordenar los nodos filtrados por la posición y de manera ascendente
  nodesX0.sort((a, b) => a.position.y - b.position.y);

  // Definir incremento en el eje y
  const incrementoY = 50;

  // Buscar la primera posición disponible
  let posX = 0;
  let posY = 0;

  for (let i = 0; i < nodesX0.length; i++) {
    if (nodesX0[i].position.y !== posY) {
      // Encontrada una posición disponible
      break;
    }
    posY += incrementoY;
  }

  return { x: posX, y: posY };
};

export const getPosition = (event, nodes, screenToFlowPosition) => {
  if (event.type === "click") {
    return searchNodesPosition(nodes);
  }
  return screenToFlowPosition({
    x: event.clientX,
    y: event.clientY,
  });
};

export const colorByType = (type) => {
  return constants.nodeTypes[type].color
};

export const nameByType = (type) => {
  return constants.nodeTypes[type].name
};

export const createPlannerNode = (id, position, type) => ({
  id,
  position,
  data: {
    label: nameByType(type),
    name: nameByType(type),
    variables: [],
    type,
    logic: "",
    filetype: "",
    filename: "",
    requestlimit: "",
    documentlimit: "",
    who: { label: "", value: "" },
    must: false,
  },
  style: { backgroundColor: colorByType(type), zIndex: 1 },
  className: "base-border-node",
  type: constants.nodeByType.plannerNode,
  selected: true,
});

export const createStickyNote = (id, position, onChange) => ({
  id,
  position,
  type: constants.nodeByType.stickyNote,
  selected: true,
  height: 250,
  width: 290,
  data: {
    type: "note",
    onChange: onChange,
    content:
      '##Nueva Nota\n\nEdita con doble click. <a href="https://valeria.moodrobotics.com/docs/cartridge/" target="_blank">Info!</a>',
  },
});
