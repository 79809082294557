import React from "react";
import "./Menu.css";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const Menu = () => {
  const { setupData } = useAppContext();

  return (
    <div className="demo-menu">
      <div>
        <img src={setupData.app?.logo} alt="logo" />
      </div>
      <div className="demo-menu-title">Inicio</div>
      <div className="demo-menu-navigation">
        <div className="demo-menu-link">Inicio</div>
        <div className="demo-menu-link demo-menu-link-active">
          Admin
          <div className="demu-menu-navigation-dropdown">
            <div className="demo-menu-link">Setup</div>
            <div className="demo-menu-link">Usuarios</div>
            <div className="demo-menu-link">Exportar</div>
            <div className="demo-menu-link">Importar</div>
            <div className="demo-menu-link">Update</div>
          </div>
        </div>
        <div className="demo-menu-link">Trabajo</div>
        <div className="demo-menu-link">User</div>
      </div>
    </div>
  );
};

export default Menu;
