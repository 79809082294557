import { HttpMethods } from "./HttpMethods.js";
import { parseViewData } from "../parseDataApi.js";
import {
  addErrorMessageGlobal,
  addMessageGlobal,
} from "src/modules/contexts/MessageContextProvider.js";

export class View {
  static async read(viewcode) {
    const response = await HttpMethods.request_get(`/v1/view/read/${viewcode}`);
    return response?.view;
  }

  static async list() {
    const response = await HttpMethods.request_get("/v1/view/list");
    if (!response) {
      addErrorMessageGlobal("Error al obtener los views");
    }
    return response?.views;
  }

  static async listflat(first = 0, pagination = 100) {
    let response = await HttpMethods.request_get(`/v1/view/list?format=flat`);
    if (!response) {
      addErrorMessageGlobal("Error al obtener los views");
      response = [];
    }
    return response;
  }

  static async create(data) {
    const parsedData = parseViewData(data);
    const response = await HttpMethods.request_post(
      "/v1/view/create",
      parsedData
    );
    if (response.error) {
      addErrorMessageGlobal(
        `Error al crear el view.\nError: ${response.error}: ${response.message}`
      );
    }
    return response?.view;
  }

  static async update(data) {
    const parsedData = parseViewData(data);
    const response = await HttpMethods.request_post(
      "/v1/view/update",
      parsedData
    );
    if (response.error) {
      addErrorMessageGlobal(
        `Error al actualizar el view.\nError: ${response.error}: ${response.message}`
      );
    }
    return response?.view;
  }

  static async crupdate(data) {
    const parsedData = parseViewData(data);
    const response = await HttpMethods.request_post(
      "/v1/view/crupdate",
      parsedData
    );
    if (response.error) {
      addErrorMessageGlobal(
        `Error al actualizar el view.\nError: ${response.error}: ${response.message}`
      );
    }
    return response?.view;
  }

  static async delete(viewcode) {
    const response = await HttpMethods.request_delete(
      "/v1/view/delete",
      viewcode
    );
    if (response?.result) {
      addMessageGlobal(`${response.result}`);
    }
    return response;
  }
}
