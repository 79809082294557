import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Activity {
  static async read() {
    const response = await HttpMethods.request_get("/v1/activity/read");
    if (!response)
      addErrorMessageGlobal("Error al leer la actividad del usuario");
    return response;
  }

  static async list() {
    const response = await HttpMethods.request_get("/v1/activity/list");
    if (!response)
      addErrorMessageGlobal("Error al leer la actividad del usuario");
    return response;
  }

  /**
   *
   * @param {string} action [start, pause, continue, stop]
   * @returns
   */
  static async action(action) {
    const response = await HttpMethods.request_get(
      `/v1/activity/action/${action}`
    );
    if (!response) addErrorMessageGlobal("Error al generar nueva actividad");

    return response;
  }
}
