import React from "react";
import DefaultSidebarItems from "../DefaultSidebarItems";
import Select from "../../../Select/Select";
import TextArea from "../../../Text Area/TextArea";

export const AiNodeSidebar = ({ data, onChange, agentOptions, availableVariables }) => {
  return (
    <>
      <DefaultSidebarItems data={data} onChange={onChange} />
      <Select
        name="agent"
        value={agentOptions.find(
          (opt) => opt.value === data.agent
        )}
        label="Agente"
        onChange={onChange("agent")}
        options={agentOptions}
      />
      <TextArea
        name="prompt"
        value={data.prompt || ""}
        label="Prompt"
        onChange={onChange("prompt")}
      />
      <Select
        creatable={true}
        onCreateOption={(e) => {
          onCreateOption(e);
          onChange("variable")(e);
        }}
        options={availableVariables}
        name={`variable`}
        value={
          availableVariables.find(
            (data) => data.value === data.variable
          ) ||
          (data.variable && {
            label: data.variable,
            value: data.variable,
          })
        }
        placeholder="dossier.file.name"
        onChange={onChange(`variable`)}
        label="Variable"
      />
    </>
  );
};

export default AiNodeSidebar;
